import React, { useState, useMemo, useEffect } from "react";
import { Layout as SideBar, Menu, Dropdown, Avatar } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  DesktopOutlined,
  CloudUploadOutlined,
  SettingFilled,
  EnvironmentOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { useLocation, Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import AuthService from "../auth/AuthService";
import useWindowWidth from "../common/CommonFun";
import AppLogo from "../../assets/images/PrefectosLogos.png";
import AppIcon from "../../assets/images/AppIcon.png";
import moment from "moment";
import Loading from "../loading/Loading";


import {
    MoveToInbox as MoveToInboxIcon,
    Dashboard as DashboardIcon,
    Route as RouteIcon,
    People as PeopleIcon,
    LocalShipping as LocalShippingIcon,
    Engineering as EngineeringIcon,
    PrecisionManufacturing as PrecisionManufacturingIcon,
    Print as PrintIcon,
    PlaylistAddCheck as PlaylistAddCheckIcon,
    LocationCity as LocationCityIcon,
    AccountBalance as AccountBalanceIcon,
    Inventory2 as Inventory2Icon,
    CorporateFare,
    RecentActors,
    PeopleAlt as PeopleAltIcon,
    AltRoute as AltRouteIcon,
    Home as HomeIcon,
    CarCrash as CarCrashIcon,
    MiscellaneousServicesOutlined,
    AccessAlarmOutlined
  } from "@mui/icons-material";
import { modulesPermissions, userRolesTypes } from "../common/constants";
import CopyRight from "../copyRight/CopyRight";
import { Typography,Stack } from '@mui/material';
import { makeStyles } from "@mui/styles";

const Auth = new AuthService();

const { Header, Content, Sider, Footer } = SideBar;

const useStyles = makeStyles((theme) => ({
 
  siderLayout: {
    "& .ant-menu-dark.ant-menu-inline .ant-menu-item" : {
      paddingLeft: '30px !important'
    }
  },
}));

const NewAppLayout = () => {
  const classes = useStyles();
  const activeTab = useSelector((state) => state.UIState.activeTab);
  const { type, roles } = useSelector((state) => state.AppState?.userTypes);
  const [currTab, setCurrTab] = useState(activeTab);
  const name = localStorage.getItem("logged_in_user_name");

  const currWidth = useWindowWidth();

  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = [
    {
      label: "Home",
      key: "/home",
      path: "/home",
      filterPath: '/home',
      onClick: () => handleMenuItemClick("/home"),
      icon: <HomeIcon />,
      children: null,
    },
    {
      label: "Dashboard",
      key: "/dashboard",
      path: "/dashboard",
      filterPath: '/dashboard',
      onClick: () => handleMenuItemClick("/dashboard"),
      icon: <DashboardIcon/>,
      children: null,
    },
    {
      label: "Imports",
      key: "/imports",
      path: "/imports",
      filterPath: '/imports',
      onClick: () => handleMenuItemClick("/imports"),
      icon: <CloudUploadOutlined />,
      children: null,
    },
    {
      label: "Users",
      key: "/users",
      path: "/users",
      onClick: () => handleMenuItemClick("/users"),
      icon: <PeopleAltIcon />,
      children: null,
    },
    {
      label: "Routing",
      key: "/routing",
      filterPath: '/routes',
      icon: <AltRouteIcon />,
      children: [
        {
          label: "Routes",
          key: "/routes",
          path: "/routes",
          onClick: () => handleMenuItemClick("/routes"),
          icon: <RouteIcon />,
        },
        {
          label: "Vehicles",
          key: "/vehicles",
          path: "/vehicles",
          onClick: () => handleMenuItemClick("/vehicles"),
          icon: <LocalShippingIcon />,
        },
        {
          label: "Drivers",
          key: "/drivers",
          path: "/drivers",
          onClick: () => handleMenuItemClick("/drivers"),
          icon: <RecentActors />,
        },
        {
          label: "Helpers",
          key: "/helpers",
          path: "/helpers",
          onClick: () => handleMenuItemClick("/helpers"),
          icon: <PeopleIcon />,
        },
        {
          label: "Rosters",
          key: "/rosters",
          path: "/rosters",
          onClick: () => handleMenuItemClick("/rosters"),
          icon: <CorporateFare />,
        },
        {
          label: "PCN",
          key: "/pcn",
          path: "/pcn",
          onClick: () => handleMenuItemClick("/pcn"),
          icon: <PlaylistAddCheckIcon />,
        },
        {
          label: "Accidents",
          key: "/accident",
          path: "/accident",
          onClick: () => handleMenuItemClick("/accident"),
          icon: <CarCrashIcon />,
        },
      ],
    },
    {
      label: "Maintenance",
      key: "/maintenance",
      filterPath: '/maintenance',
      icon: <EngineeringIcon />,
      children: [
        {
          label: "Equipment",
          key: "/equipments",
          path: "/equipments",
          onClick: () => handleMenuItemClick("/equipments"),
          icon: <PrecisionManufacturingIcon />,
        },
        {
          label: "Maintenance",
          key: "/maintenance",
          path: "/maintenance",
          onClick: () => handleMenuItemClick("/maintenance"),
          icon: <EngineeringIcon />,
        },
      ],
    },
    {
      label: "Reports",
      key: "/reports",
      filterPath: '/view_reports',
      icon: <PrintIcon />,
      children: [
        {
          label: "Pallets Builder",
          key: "/pallets_report",
          path: "/pallets_report",
          onClick: () => handleMenuItemClick("/pallets_report"),
          icon: <MoveToInboxIcon />,
        },
        {
          label: "Print Report",
          key: "/print_reports",
          path: "/print_reports",
          onClick: () => handleMenuItemClick("/print_reports"),
          icon: <PrintIcon />,
        },
        {
          label: "View Reports",
          key: "/view_reports",
          path: "/view_reports",
          onClick: () => handleMenuItemClick("/view_reports"),
          icon: <PlaylistAddCheckIcon />,
        },
        {
          label: "Maintenance",
          key: "/maintenance_report",
          path: "/maintenance_report",
          onClick: () => handleMenuItemClick("/maintenance_report"),
          icon: <PrintIcon />,
        },
        {
          label: "Defect Report",
          key: "/defect_report",
          path: "/defect_report",
          onClick: () => handleMenuItemClick("/defect_report"),
          icon: <PlaylistAddCheckIcon />,
        },
        {
          label: "Location Report",
          key: "/location_report",
          path: "/location_report",
          onClick: () => handleMenuItemClick("/location_report"),
          icon: <EnvironmentOutlined />,
        },
        {
          label: "Driver Contact",
          key: "/contact_report",
          path: "/contact_report",
          onClick: () => handleMenuItemClick("/contact_report"),
          icon: <PlaylistAddCheckIcon />,
        },
        {
          label: "Fuel Report",
          key: "/fuel_report",
          path: "/fuel_report",
          onClick: () => handleMenuItemClick("/fuel_report"),
          icon: <PlaylistAddCheckIcon />,
        },
        {
          label: "Service Report",
          key: "/service_report",
          path: "/service_report",
          onClick: () => handleMenuItemClick("/service_report"),
          icon: <SettingFilled />,
        },
        // {
        //   label: "Discounts",
        //   key: "/discounts",
        //   path: "/discounts",
        //   onClick: () => handleMenuItemClick("/discounts"),
        //   icon: <PlaylistAddCheckIcon />,
        // },
      ],
    },
    {
     label: "Reminders",
      key: "/reminders",
      icon: <LocalShippingIcon />,
      children: [
          // {
          //   label: "Service",
          //   key: "/vehicle_service",
          //   onClick: () => handleMenuItemClick("/vehicle_service"),
          //   icon: <MiscellaneousServicesOutlined />,
          // },
          {
            label: "Service Reminder",
            key: "/vehicle_reminder",
            onClick: () => handleMenuItemClick("/vehicle_reminder"),
            icon: <AccessAlarmOutlined />,
          },
          {
            label: "Driver Reminder",
            key: "/driver_reminder",
            onClick: () => handleMenuItemClick("/driver_reminder"),
            icon: <AccessAlarmOutlined />,
          },
          {
            label: "Helper Reminder",
            key: "/helper_reminder",
            onClick: () => handleMenuItemClick("/helper_reminder"),
            icon: <AccessAlarmOutlined />,
          },
          // {
          //   label: "Contact Reminders",
          //   key: "/contact_reminder",
          //   onClick: () => handleMenuItemClick("/contact_reminder"),
          //   icon: <ContactsOutlined />,
          // },
      ]
    }
    //   {
    //     label: 'WMS',
    //     icon: <Inventory2Icon />,
    //     children: [
    //         {
    //             label: 'Aisles',
    //             onClick: () => handleMenuItemClick('/aisles'),
    //             icon: <LocationCityIcon />,
    //         },
    //         {
    //             label: 'Locations',
    //             onClick: () => handleMenuItemClick('/locations'),
    //             icon: <AccountBalanceIcon />,
    //         },
    //         {
    //             label: 'Products',
    //             onClick: () => handleMenuItemClick('/products'),
    //             icon: <Inventory2Icon />,
    //         },
    //     ]
    //   },
  ];

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    Auth.logout();
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  useMemo(() => {
    dispatch({ type: "SET_ACTIVE_TAB", payload: location.pathname });
    setCurrTab(activeTab);
  }, [location, dispatch, activeTab]);

  const removeExtraSlashDataFromRoute = (route) => {
    const routeArray = route?.split("/");
    const newRouteArray = routeArray?.filter((item) => item !== "");
    return "/" + newRouteArray?.[0]?.toString();
  };
  const token = localStorage.getItem('id_token')
  if(token) {

    const tokenObject = JSON.parse(atob(token?.split('.')[1]));
    if (tokenObject?.payment_authorizer === 1 || type === 1) {
      const reportsMenu = menuItems.find(item => item.key === "/reports");
      if (reportsMenu && reportsMenu.children) {
        reportsMenu.children.push({
          label: "Discounts",
          key: "/discounts",
          path: "/discounts",
          onClick: () => handleMenuItemClick("/discounts"),
          icon: <PlaylistAddCheckIcon />,
        });
      }}
  }

  

  const menu = (
    <Menu>
      <Menu.Item
        key="sign-out"
        onClick={handleLogout}
        icon={<LogoutOutlined />}
      >
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const defaultOpenKeys = menuItems
  .filter((item) => Array.isArray(item.children))
  .map((item) => item.key)

  useEffect(() => {
    const pathname = location.pathname;
    const route = pathname?.split("/")[1];
    const routeTitles = {
      dashboard: "Dashboard",
      helpers: "Helpers",
      drivers: "Drivers",
      vehicles: "Vehicles",
      routes: "Routes",
      rosters: "Rosters",
      pcn: "PCN",
      home: "Home",
      accident: "Accident Report",
      equipments: "Equipments",
      maintenance: "Maintenance",
      maintenance_report: "Maintenance Reports",  
      print_reports: "Print Reports",
      view_reports: "View Reports",
      pallets_report: "Pallet Reports",
      discounts: "Discounts",
      vehicle_service: "Vehicle Service",
      vehicle_reminder: "Service Reminder",
      driver_reminder: "Driver Reminder",
      helper_reminder: "Helper Reminder",
      contact_reminder: "Contact Reminder",
      defect_report: "Defect Report",
      location_report: "Location Report",
      fuel_report: "Fuel Report",
      contact_report: "Driver Contact Report",
      service_report: "Service Report",
      imports: "Imports",
    };

    document.title = `Prefectos - ${routeTitles[route] || "TMS"}`;
  }, [location]);

  useEffect(() => {
    if(Auth.loggedIn() && roles.length <= 0 && type === 2 ) {
        Auth.logout();
    }
  }, [roles]);

  let sidebarItems = [];
  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const allowedRoutes = matchedRoles.flatMap((matchedRole) => {
    return modulesPermissions
      .filter((module) => matchedRole.modules.includes(module.title))
      .flatMap((module) => module.routes);
  });
  if (type == 0) {
    // const usersItem = SideBarList.find((item) => item.path === "/users");
    // if (usersItem) {
    //   sidebarItems.push(usersItem);
    // }
    const homeItem = menuItems.find((item) => item.path === "/home");
    const usersItem = menuItems.find((item) => item.path === "/users");
    if (homeItem) {
        sidebarItems.push(homeItem);
    }
    
    if (usersItem) {
        sidebarItems.push(usersItem);
    }
  } else if (type == 1) {
    sidebarItems = menuItems.filter((item) => item.path !== "/users");
  } else {
    sidebarItems = menuItems.filter((item) =>
      allowedRoutes.includes(item.filterPath)
    );
    sidebarItems.forEach((item) => {
      if (item.children) {
        item.children = item.children.filter((child) =>
          allowedRoutes.includes(child.path)
        );
      }
    });

    // const filteredSidebarItems = SideBarList.filter(item => allowedRoutes.includes(item.filterPath));
    // sidebarItems = filteredSidebarItems
  }

  return (
    <SideBar className={classes.siderLayout} style={{ height: "100vh" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo-container">
          {/* <Link to="/pin"> */}
          {!collapsed ? (
            <img style={{ height: "32px" }} src={AppLogo} alt="appLogo" />
          ) : (
            <img style={{ height: "32px" }} src={AppIcon} alt="appIcon" />
          )}
        </div>
        <div className="demo-logo-vertical" />
        {/* <Menu theme="dark" mode="inline" items={menuItems} /> */}
        
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={removeExtraSlashDataFromRoute(currTab)}
          // defaultOpenKeys={defaultOpenKeys}
        >
          {sidebarItems.map((item) =>
            Array.isArray(item.children) ? (
              <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.children.map((child) => (
                  <Menu.Item
                    key={child.key}
                    icon={child.icon}
                    // onClick={() => handleMenuItemClick(child.key)}
                  >
                    <Link className="no-underline" key={child.key} to={child.key}>
                            {child.label}
                          </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                // onClick={() => handleMenuItemClick(item.key)}
              >
                <Link className="no-underline" key={item.key} to={item.key}>
                            {item.label}
                          </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
      <SideBar style={{ padding: "0" }}>
        <Header
          style={{
            padding: "0",
            background: "white",
            borderBottom: "1px solid lightBlue",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className=" header-container"
          >
            <div>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <>
                <>
                  {currWidth >= 960 && (
                    <>
                      {name && (
                        <span className="light-span">
                          Hello,{" "}
                          <strong className="user-name">
                            {name ? name : ""}
                          </strong>
                        </span>
                      )}
                    </>
                  )}
                  <Dropdown overlay={menu} arrow>
                    <Avatar className="avatar-extra" size="large">
                      {name ? name.charAt(0).toUpperCase() : "A"}
                    </Avatar>
                  </Dropdown>
                </>
              </>
            </div>
          </div>
        </Header>
        <div className="content-wrapper">
          <Content style={{ background: "white" }} className="context-box">
            {/* {contentLoading && <ContentSpinner />} */}
            <Outlet />
          </Content>
          
        </div>

      <Footer style={{ display: "flex", justifyContent: "center", padding: '5px 5px'}}>
        
      Prefectos ©{new Date().getFullYear()}
      </Footer>
      </SideBar>
      <Loading />
    </SideBar>
  );
};

export default NewAppLayout;
