import * as React from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Pagination
} from "@mui/material";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { availableDays, vehicleColors, vehicleMakeOptions, vehicleModelOptions, vehicleServiceTasks, vehicleStatusOptions, vehicleTypeOptions, vehicleTypes } from "../../Components/common/constants";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import { makeStyles } from "@mui/styles";
import { Button, Col, message, Popconfirm, Row, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined, DeleteFilled, EditFilled, ExclamationCircleOutlined, HistoryOutlined, PlusOutlined } from "@ant-design/icons";
import AddServiceTaskModal from "../../Components/Modals/AddServiceTaskModal";
import CompleteReminderModal from "../../Components/Modals/CompleteReminderModal";
import EditServiceTaskModal from "../../Components/Modals/EditServiceTaskModal";
import TaskHistoryModal from "../../Components/Modals/TaskHistoryModal";
import { useSelector } from "react-redux";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .example-custom-input": {
      width: "100% !important",
    },
  }
}));

export default function Vehicle(props) {

  const navigate = useNavigate();
  const classes = useStyles()
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();
  const userType = useSelector((state) => state.AppState?.userTypes?.type);

  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [vehicleDetails, setVehicleDetails] = React.useState({
    registrationNumber: "",
    capacity: "",
    vehicleType: "",
    maxWeight: "",
    height: "",
    year: "",
    make: "",
    model: "",
    status: "",
    color: "",
  });

  const [errors, setErrors] = React.useState({
    registrationNumber: "",
    capacity: "",
    vehicleType: "",
    maxWeight: "",
    height: "",
    year: "",
    make: "",
    model: "",
    status: "",
    color: "",
  });

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [allFuelRecords, setAllFuelRecords] = React.useState([]);
  const [allServiceRecords, setAllServiceRecords] = React.useState([]);
  const [allReminders, setAllReminders] = React.useState([]);
  const [allVehicle, setAllVehicle] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);

  const [selectedRecord, setSelectedRecord] = React.useState("");
  const [historyRecord, setHistoryRecord] = React.useState([]);

  const [reminderPage, setReminderPage] = React.useState(1);
  const [reminderPageChange, setReminderPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPagesReminder, setTotalPagesReminder] = React.useState(0);

  const reminderColumns = [
    
    {
      title: 'Next Due',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      }

    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date, type }) => {
        const serviceObject = vehicleServiceTasks.find((obj) => obj.id === type);
        const info = getStatusInfo(moment(due_date, "YYYY-MM-DD"), serviceObject?.thresholdDays);
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    {
      align: "center",
      title: "Service Task",
      render: (record) => {
          const vehicle = vehicleServiceTasks.find((obj) => obj.id === record?.type);
          // const title = vehicle?.title || "";
        return (
          <>
          <span>{vehicle?.title || ""}</span><br/>
          <span style={{ fontSize: '10px', color: 'darkgrey'}}>{vehicle?.message}</span>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Last Completed",
      render: (record) => {
        return (
          <>
          <span>{record.last_completed ? moment(record.last_completed, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      dataIndex: "",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Resolve">
            <CheckCircleFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "green",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsCompleteModalOpen(true)
              }}
            />
            </Tooltip>
            {
              record?.last_completed && (
                <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
              )
            }
            
            <Tooltip title="Edit">
            <EditFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "#006cb8",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsEditModalOpen(true)
              }}
            />
            </Tooltip>
            {
              !record?.last_completed && (
                <Tooltip title="Delete">
            <Popconfirm
                  title="Delete Record"
                  description="Are you sure you want to delete this record?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDelete(record.id)}
                  placement="topLeft"
                  okButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                  cancelButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                >
            <DeleteFilled
              style={{ fontSize: "25px", cursor: "pointer", color: "#cd1437" }}
            />
            </Popconfirm>
            </Tooltip>
              )
            }
            
            
          </div>
        );
      },
    },
  ];

  const fuelCol = [
    {
      title: 'Date',
      dataIndex: "fuel_date",
      key: "fuel_date",
    },
    {
      title: 'Volume',
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: 'Unit Price',
      dataIndex: "unit_price",
      key: "unit_price",
    },
    {
      title: 'Total Price',
      render: (record) => {
          const totalPrice = record?.volume * record.unit_price
          return  <>{`£ ${totalPrice.toFixed(2)}`  }</>;
        },
    },
    {
      title: 'Fuel Type',
      render: (record) => {
        return record?.fuel_type == 1 ? 'Diesel' : 'Petrol'
      },
    },
    {
      title: 'Odometer',
      key: "odometer",
      dataIndex: "odometer"
    },
    {
      title: 'Vendor',
      key: "vendor",
      dataIndex: "vendor"
    },
  ];

  const serviceCol = [
    {
      title: 'Date',
      dataIndex: "service_date",
      key: "service_date",
    },
    // {
    //   title: 'Vehicle',
    //   render: (record) => {
    //     return record?.vehicle_id
    //       ? allVehicle.filter((obj) => obj.id == record.vehicle_id)[0]?.registration_number
    //       : ""
    //   },
    // },
    {
      title: 'Vendor',
      key: "vendor",
      dataIndex: "vendor"
    },
    {
      title: 'Service Type',
      render: (record) => {
        return record?.service_type == 1
          ? "Valet"
          : "Valet"
      },
    },
  ];

  const getStatusInfo = (date, thresholdDays) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference == thresholdDays || (daysDifference < thresholdDays && daysDifference > 0 ) || daysDifference === 0 ) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else if (daysDifference > thresholdDays) {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <CheckCircleOutlined />,
      };
    }
  };
  

  const getIntervals = (thresholdDays) => {
    const years = thresholdDays / 365;
    const months = thresholdDays / 30;
    const weeks = thresholdDays / 7;
  
    if (Number.isInteger(years)) {
      return `every ${years} year${years > 1 ? 's' : ''}`;
    } else if (Number.isInteger(months)) {
      return `every ${months} month${months > 1 ? 's' : ''}`;
    } else if (Number.isInteger(weeks)) {
      return `every ${weeks} week${weeks > 1 ? 's' : ''}`;
    } else {
      return `every ${thresholdDays} day${thresholdDays > 1 ? 's' : ''}`;
    }
  };

  const handleDelete = async (recordID) => {
    showLoading(layoutDispatch)
    const params = {
      method: "DELETE",
      body: JSON.stringify({
        id: recordID,
      }),
    };
    try {
      const res = await Auth.fetch('/reminder/reminder', params)
      if(res?.ack) {
        message.success(res.message)
        getData()
      } else  {
        message.error(res.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  React.useEffect(() => {

    if (id > 0) {
      getData()
      getAllVehicles()
      // getHistory()
    }

  }, []);

  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllVehicle(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  const getFuelReport = async (data) => {
    showLoading(layoutDispatch);
    try {
      const fuelRes = await Auth.fetch("/report/fuel-records", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if(fuelRes?.ack) {
      setAllFuelRecords(fuelRes?.fuel_entries);
      } else {
      setAllFuelRecords([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getServiceReport = async (data) => {
    showLoading(layoutDispatch);
    try {
      const fuelRes = await Auth.fetch("/report/service-records", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if(fuelRes?.ack) {
      setAllServiceRecords(fuelRes?.service_entries);
      } else {
      setAllServiceRecords([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getHistory = async (data) => {
    const DataToSend = {
      vehicle_id: data?.vehicle_id,
      type: data?.type
    }
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/reminder/vehicle-reminders-history", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })

      if(res?.ack) {
      console.log('res', res);
      if(res?.reminders.length > 0) {
        setHistoryRecord(res?.reminders)
        setIsHistoryModalOpen(true)
      } else  {
        message?.info('No related history found')
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getReminder = async () => {
    const dataToSend = {
      vehicle_id: Number(id),
    }
    if (reminderPage && reminderPageChange) dataToSend.page = reminderPage;
    showLoading(layoutDispatch);
    try {
      const reminderRes = await Auth.fetch("/reminder/reminders", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      })

      if(reminderRes?.ack) {
      setAllReminders(reminderRes?.reminders);
      setTotalPagesReminder(Math.ceil(reminderRes?.total_count / limit));
      } else {
      setAllReminders([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  React.useEffect(() => {
    getReminder()
  }, [reminderPage]);




  const getData = async () => {
    showLoading(layoutDispatch);
    try {
      const vehicleRes = await Auth.fetch(`/vehicle/vehicle?id=${id}`)
      if (vehicleRes.ack) {
        const vehicleCapacity = vehicleTypes.find(obj => obj.id == vehicleRes?.vehicle?.type);
        const vehicleType = vehicleTypeOptions.find(obj => obj.id == vehicleRes?.vehicle?.vehicle_spec);
        const vehicleMake = vehicleMakeOptions.find(obj => obj.id == vehicleRes?.vehicle?.make);
        const vehicleModel = vehicleModelOptions.find(obj => obj.id == vehicleRes?.vehicle?.model);
        const vehicleColor = vehicleColors.find(obj => obj.id == vehicleRes?.vehicle?.color);
        setVehicleDetails((prev) => ({
          ...prev,
          registrationNumber: vehicleRes.vehicle.registration_number,
          capacity: vehicleCapacity,
          vehicleType: vehicleType, 
          year: vehicleRes?.vehicle?.year,
          maxWeight: vehicleRes?.vehicle?.max_weight,
          height: vehicleRes?.vehicle?.height,
          make: vehicleMake,
          model: vehicleModel,
          color: vehicleColor
        }));

        const dataToSend = {
          vehicle_id: Number(id),
        }

        await Promise.all([
          getFuelReport(dataToSend),
          getServiceReport(dataToSend),
          getReminder()
        ]);
      }

    } catch (error) {
      console.log(error);
      
    } finally {
      hideLoading(layoutDispatch)
    }
  } 

  const onClickCancel = () => {
    navigate("/vehicles");
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;

    const checkAndSetError = (condition, key, message) => {
      if (condition) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: message }));
        setType('error');
        setLoadMessage(`${message}${str}`);
        return true;
      }
      return false;
    };

    if (
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.registrationNumber), 'registrationNumber', 'Registration number is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.capacity?.id), 'capacity', 'Vehicle Weight is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.vehicleType?.id), 'vehicleType', 'Vehicle type is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleDetails.year), 'year', 'Vehicle year is required') ||
      userType === 1 && checkAndSetError(isEmptyOrUndefined(vehicleDetails.maxWeight), 'maxWeight', 'Vehicle max weight is required') ||
      userType === 1 && checkAndSetError(isEmptyOrUndefined(vehicleDetails.height), 'height', 'Vehicle height is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.make?.id), 'make', 'Vehicle make is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.model?.id), 'model', 'Vehicle model is required') ||
      // checkAndSetError(isEmptyOrUndefined(vehicleDetails?.status?.id), 'status', 'Vehicle status is required') || 
      checkAndSetError(isEmptyOrUndefined(vehicleDetails?.color?.id), 'status', 'Vehicle color is required') 
    ) {
      return;
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/vehicle/vehicle", {
        method: "PUT",
        body: JSON.stringify({
          registration_number: vehicleDetails?.registrationNumber,
          type: vehicleDetails?.capacity?.id,
          vehicle_spec: vehicleDetails?.vehicleType?.id,
          year: vehicleDetails?.year,
          max_weight: vehicleDetails?.maxWeight,
          height: vehicleDetails?.height,
          make: vehicleDetails?.make?.id,
          model: vehicleDetails?.model?.id,
          color: vehicleDetails?.color?.id,
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/vehicles");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/vehicle/vehicle", {
        method: "POST",
        body: JSON.stringify({
          registration_number: vehicleDetails?.registrationNumber,
          type: vehicleDetails?.capacity?.id,
          vehicle_spec: vehicleDetails?.vehicleType?.id,
          year: vehicleDetails?.year,
          max_weight: vehicleDetails?.maxWeight,
          height: vehicleDetails?.height,
          make: vehicleDetails?.make?.id,
          model: vehicleDetails?.model?.id,
          color: vehicleDetails?.color?.id,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/vehicles");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleReminderPageChange = (event, value) => {
    setReminderPage(value);
    setReminderPageChange(true)
  };

  React.useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <div className={classes.dataSheet}>
      <Grid item xs={12}>
      <h2> {id > 0 ? "Update" : "New"} Vehicle</h2>
      </Grid>

      <div className="accident-box" style={{ marginTop: '10px' }}>
          <span className="accident-box-heading">
            Vehicle Details
          </span>
          <Row gutter={[16, 16]}>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="registrationNumber"
                size="small"
                label="Name"
                variant="outlined"
                placeholder="Name"
                value={vehicleDetails?.registrationNumber}
                required
                onChange={(e) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    registrationNumber: e.target.value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    registrationNumber: '',
                  }));
                }}
                error={errors.registrationNumber === "" ? false : true}
                helperText={errors?.registrationNumber}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="type"
                options={vehicleTypes}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.capacity}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    capacity: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    capacity: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Weight Type"
                    placeholder="Type"
                    size="small"
                    required
                    error={errors?.capacity === "" ? false : true}
                    helperText={errors?.capacity}
                  />
                )}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="type"
                options={vehicleTypeOptions}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.vehicleType}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    vehicleType: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    vehicleType: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Vehicle Type"
                    placeholder="Please Select"
                    size="small"
                    required
                    error={errors?.vehicleType === "" ? false : true}
                    helperText={errors?.vehicleType}
                  />
                )}
              />
            </Col>
            { userType === 1 && (
              <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Enter Max Weight"
                type="number"
                value={vehicleDetails?.maxWeight}
                required
                disabled={userType != 1}
                onChange={(e) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    maxWeight: e.target.value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    maxWeight: '',
                  }));
                }}
                error={errors?.maxWeight === "" ? false : true}
                helperText={errors?.maxWeight}
                id="weight"
                label="Max Weight (KGs)"
              />
            </Col>
            )}
            
            {userType === 1 &&  (
              <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Enter Height"
                type="number"
                value={vehicleDetails?.height}
                required
                onChange={(e) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    height: e.target.value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    height: '',
                  }));
                }}
                error={errors?.height === "" ? false : true}
                helperText={errors?.height}
                id="height"
                label="Height (Meters)"
              />
            </Col>
            )}
            


            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Enter Year"
                type="number"
                value={vehicleDetails?.year}
                required
                onChange={(e) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    year: e.target.value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    year: '',
                  }));
                }}
                error={errors?.year === "" ? false : true}
                helperText={errors?.year}
                id="year"
                label="Year"
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="make"
                options={vehicleMakeOptions}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.make}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    make: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    make: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Make"
                    placeholder="Please Select"
                    size="small"
                    required
                    error={errors?.make === "" ? false : true}
                    helperText={errors?.make}
                  />
                )}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="model"
                options={vehicleModelOptions}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.model}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    model: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    model: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Model"
                    placeholder="Please Select"
                    size="small"
                    required
                    error={errors?.model === "" ? false : true}
                    helperText={errors?.model}
                  />
                )}
              />
            </Col>

            {/* <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="status"
                options={vehicleStatusOptions}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.status}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    status: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    status: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Status"
                    placeholder="Please Select"
                    size="small"
                    required
                    error={errors?.status === "" ? false : true}
                    helperText={errors?.status}
                  />
                )}
              />
            </Col> */}

<Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="color"
                options={vehicleColors}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={vehicleDetails?.color}
                onChange={(e, values) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    color: values,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    color: '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Color"
                    placeholder="Please Select"
                    size="small"
                    required
                    error={errors?.color === "" ? false : true}
                    helperText={errors?.color}
                  />
                )}
              />
            </Col>
            
            {/* <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Color"
                value={vehicleDetails?.color}
                required
                onChange={(e) => {
                  setVehicleDetails((prev) => ({
                    ...prev,
                    color: e.target.value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    color: '',
                  }));
                }}
                error={errors?.color === "" ? false : true}
                helperText={errors?.color}
                id="color"
                label="Color"
              />
            </Col> */}
            
          </Row>
          <div style={{ display: 'flex', justifyContent: 'end',marginTop: '30px'}}>
        <FormButton
          type="close"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        </div>
        {
          id > 0 && (
            <>
            
                <div className="accident-box" style={{ marginTop: '30px' }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                  <span className="accident-box-heading">
                    Service Reminders
                  </span>
                <Button onClick={()=> {
                  setIsModalOpen(true)
                  
                }} type="primary" icon={<PlusOutlined />}>Add Service Reminder</Button>
                  </div>

               <Table dataSource={allReminders} columns={reminderColumns} style={{ marginTop: "4px"}} pagination={false} />
               <div style={{ display: 'flex', justifyContent: "end" , marginTop: "10px" }}>
                <Pagination
                  page={reminderPage}
                  count={totalPagesReminder}
                  variant="outlined"
                  color="primary"
                  onChange={handleReminderPageChange}
                />
              </div>
               </div>
                <div className="accident-box" style={{ marginTop: '30px' }}>
                  <span className="accident-box-heading">
                    Fuel History
                  </span>
                <Table dataSource={allFuelRecords} columns={fuelCol} style={{ marginTop: "4px"}} pagination={false} />
                </div>
                <div className="accident-box" style={{ marginTop: '30px' }}>
                  <span className="accident-box-heading">
                    Service History
                  </span>
                <Table dataSource={allServiceRecords} columns={serviceCol} style={{ marginTop: "4px"}} pagination={false} />
                </div>
            </>
          )
        }
          
        </div>
      

      <Message type={type} msg={loadMessage} />
      <TaskHistoryModal isModalOpen={isHistoryModalOpen} setIsModalOpen={setIsHistoryModalOpen} allVehicle={allVehicle} history={historyRecord} setHistory={setHistoryRecord} />
      <AddServiceTaskModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} allVehicle={allVehicle} searchFunction={getData} newRecord={id} />
      <CompleteReminderModal isModalOpen={isCompleteModalOpen} setIsModalOpen={setIsCompleteModalOpen} selectedRecord={selectedRecord} searchFunction={getData} allVehicle={allVehicle} />
      <EditServiceTaskModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} allVehicle={allVehicle} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} searchFunction={getData} />
    </div>
  );
}
