import React, { useEffect, useState } from "react";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import Message from "../../Components/common/Message";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import DriverSheetReport from "./DriverSheetReport";
import GoodsReturnReport from "./GoodsReturnReport";
import StockDiscrepanciesReport from "./StockDiscrepanciesReport";
import { Divider, Select, Button as AntButton, Result, DatePicker as AntDate } from "antd";
import { deliveryStatuses, userRolesTypes } from "../../Components/common/constants";
import CashUpReport from "./CashUpReport";
import FinancialSummaryReport from "./FinancialSummaryReport";
import AmountDiscrepanciesReport from "./AmountDiscrepanciesReport";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    },
    "& .react-date-picker__button svg ": {
      stroke: "#b6babf",
    }
  },
  selectStyle: {
    "& .ant-select .ant-select-selector": {
      borderBottom: '1px solid #d9d9d9',
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderRadius: '0px'
    }
  }
}));


export default function ViewReports() {

  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [routes, setRoutes] = React.useState([]);
  const [routePallets, setRoutePallets] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [activeButton, setActiveButton] = useState('');

  const [driverSheetData, setDriverSheetData] = React.useState("");
  const [goodsReturnData, setGoodsReturnData] = React.useState("");
  const [cashUpData, setCashUpData] = React.useState("");
  const [financialSummaryData, setFinancialSummaryData] = React.useState("");
  const [stockDiscrepanciesData, setStockDiscrepanciesData] = React.useState("");
  const [amountDiscrepanciesData, setAmountDiscrepanciesData] = React.useState("");

  const [selectedDriverRoute, setSelectedDriverRoute] = useState([]);
  const [deliveryQuantities, setDeliveryQuantities] = React.useState({});
  const [paymnetDataPost, setPaymnetDataPost] = React.useState({});

  const [paymentAuthorizer, setPaymentAuthorizer] = React.useState([]);

  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some(item => item.title === "Finance")
  const includesRouting = matchedRoles.some(item => item.title === "Routing")
  const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
  const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")
  console.log('includesWareHouse', includesWareHouse)

  const Auth = new AuthService();

  const loadRoutes = () => {
    setType("success");
    setRoutes([]);
    setActiveButton('')
    resetReportData();

    setSelectedDriverRoute([]);
    setLoadMessage("Loading routes. Please wait ....");
    showLoading(layoutDispatch);
    Auth.post(`/route/load-locked-routes`, {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        setLoadMessage("Routes loaded .");
        setRoutes(res.routes);
        loadDailyRoute();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDailyRoute = () => {
    setRoutePallets([]);
    setType("success");

    setLoadMessage("Loading orders. Please wait ....");

    // Load saved route
    showLoading(layoutDispatch);
    Auth.fetch(
      `/route/load-daily-route?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setLoadMessage("Reports data loaded !");
          let route_pallets = res.route_pallets;

          let route_pallets1 = [];
          let pallets1 = [];
          route_pallets.map((pallet, idx) => {
            // Checking Route
            let checkRoute = route_pallets1.filter(
              (rp) => rp.route_status_id == pallet.route_status_id
            );
            if (checkRoute.length > 0) {
              // Route Already exists, check pallet in that route
              let checkPallet = checkRoute[0].pallets.filter(
                (p) => p.id == pallet.pallet_builder_id
              );
              if (checkPallet.length > 0) {
                // Pallet already added, check for Order
                let checkPalletOrder = checkPallet[0].items.filter(
                  (po) => po.internalId == pallet.internal_id
                );
                if (checkPalletOrder.length > 0) {
                  // order already added, just add the item

                  let palletOrderItem = {};
                  palletOrderItem.itemPalletId = pallet.item_pallet_id;
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  palletOrderItem.deliveredQuantity = pallet.delivered_quantity;
                  palletOrderItem.returnedQuantity = pallet.returned_quantity;
                  palletOrderItem.returnedQuantityToStock = pallet.returned_quantity_to_stock;
                  palletOrderItem.returnedQuantityToStockStatus = pallet.returned_quantity_to_stock_status;
                  palletOrderItem.returnedQuantityApprovedRec = pallet.returned_quantity_approved_rec;
                  palletOrderItem.returnedQuantityApprovedSup = pallet.returned_quantity_approved_sup;
                  palletOrderItem.deliveryStatus = pallet.delivery_status;
                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  checkPalletOrder[0].items.push(palletOrderItem);

                  checkPalletOrder[0].orderWeight += Number(
                    pallet.item_total_weight
                  );
                  // checkPalletOrder[0].orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  checkPalletOrder[0].orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );

                  checkPallet[0].weight += Number(pallet.item_total_weight);
                } else {
                  // New order, in previous pallet
                  let palletOrder = {};
                  palletOrder.id = idx + "" + 1;
                  palletOrder.rank = pallet.rank;
                  palletOrder.customerInternalId = pallet.customer_internal_id;
                  palletOrder.customerName = pallet.customer_name;
                  palletOrder.notes = pallet.notes;
                  palletOrder.received_by = pallet.received_by;
                  palletOrder.delivery_discount_req_status = pallet.delivery_discount_req_status;
                  palletOrder.delivery_discount_auth_by = pallet.delivery_discount_auth_by;
                  palletOrder.payment_req_status = pallet.payment_req_status;
                  palletOrder.payment_auth_by = pallet.payment_auth_by;
                  palletOrder.signature = pallet.signature;

                  palletOrder.invoiceInternalId = pallet.inv_internal_id;
                  palletOrder.palletOrderID = pallet.pallet_order_id;
                  // palletOrder.cashApproved = pallet.cash_approved;
                  palletOrder.customerProjectId = pallet.customer_project_id;
                  palletOrder.shipZip = pallet.ship_zip;
                  palletOrder.customerOpeningTime =
                    pallet.customer_opening_time;
                  palletOrder.terms = pallet.terms;
                  palletOrder.shipAddress1 = pallet.ship_address1;

                  palletOrder.internalId = pallet.internal_id;
                  palletOrder.invInternalId = pallet.inv_internal_id;
                  palletOrder.invoiceNo = pallet.invoice_no;
                  palletOrder.saleOrderNo = pallet.sale_order_no;

                  palletOrder.orderWeight = 0;
                  palletOrder.orderAmount = 0;
                  palletOrder.amountReceivedCash = pallet.amount_received_cash;
                  palletOrder.amountReceivedCard = pallet.amount_received_card;
                  palletOrder.amountReceivedAccount = pallet.amount_received_account;
                  palletOrder.amountReceivedCashPost = pallet.amount_received_cash_post;
                  palletOrder.amountReceivedCardPost = pallet.amount_received_card_post;
                  palletOrder.amountReceivedAccountPost = pallet.amount_received_account_post;
                  palletOrder.delivered = pallet.delivered;
                  palletOrder.files = pallet.files;


                  palletOrder.items = [];

                  let palletOrderItem = {};
                  palletOrderItem.itemPalletId = pallet.item_pallet_id;
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  palletOrderItem.deliveredQuantity = pallet.delivered_quantity;
                  palletOrderItem.returnedQuantity = pallet.returned_quantity;
                  palletOrderItem.returnedQuantityToStock = pallet.returned_quantity_to_stock;
                  palletOrderItem.returnedQuantityToStockStatus = pallet.returned_quantity_to_stock_status;
                  palletOrderItem.returnedQuantityApprovedRec = pallet.returned_quantity_approved_rec;
                  palletOrderItem.returnedQuantityApprovedSup = pallet.returned_quantity_approved_sup;
                  palletOrderItem.deliveryStatus = pallet.delivery_status;

                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  palletOrder.items.push(palletOrderItem);

                  palletOrder.orderWeight += Number(pallet.item_total_weight);
                  // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  palletOrder.orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );

                  checkPallet[0].weight += Number(pallet.item_total_weight);

                  checkPallet[0].items.push(palletOrder);
                }
              } else {
                // new pallet
                let palletObj = {};
                palletObj.id = pallet.pallet_builder_id;
                palletObj.title = pallet.title;
                palletObj.pallet_id = pallet.pallet_id;
                palletObj.weight = 0;
                palletObj.items = [];

                if (pallet.internal_id) {
                  let palletOrder = {};
                  palletOrder.id = idx + "" + 1;
                  palletOrder.rank = pallet.rank;
                  palletOrder.customerInternalId = pallet.customer_internal_id;

                  palletOrder.customerName = pallet.customer_name;
                  palletOrder.notes = pallet.notes;
                  palletOrder.received_by = pallet.received_by;
                  palletOrder.delivery_discount_req_status = pallet.delivery_discount_req_status;
                  palletOrder.delivery_discount_auth_by = pallet.delivery_discount_auth_by;
                  palletOrder.payment_req_status = pallet.payment_req_status;
                  palletOrder.payment_auth_by = pallet.payment_auth_by;
                  palletOrder.signature = pallet.signature;

                  palletOrder.invoiceInternalId = pallet.inv_internal_id;
                  palletOrder.palletOrderID = pallet.pallet_order_id;
                  // palletOrder.cashApproved = pallet.cash_approved;
                  palletOrder.customerProjectId = pallet.customer_project_id;
                  palletOrder.shipZip = pallet.ship_zip;
                  palletOrder.customerOpeningTime =
                    pallet.customer_opening_time;
                  palletOrder.terms = pallet.terms;
                  palletOrder.shipAddress1 = pallet.ship_address1;

                  palletOrder.internalId = pallet.internal_id;
                  palletOrder.invInternalId = pallet.inv_internal_id;
                  palletOrder.invoiceNo = pallet.invoice_no;
                  palletOrder.saleOrderNo = pallet.sale_order_no;

                  palletOrder.orderWeight = 0;
                  palletOrder.orderAmount = 0;

                  palletOrder.amountReceivedCash = pallet.amount_received_cash;
                  palletOrder.amountReceivedCard = pallet.amount_received_card;
                  palletOrder.amountReceivedAccount = pallet.amount_received_account;
                  palletOrder.amountReceivedCashPost = pallet.amount_received_cash_post;
                  palletOrder.amountReceivedCardPost = pallet.amount_received_card_post;
                  palletOrder.amountReceivedAccountPost = pallet.amount_received_account_post;
                  palletOrder.delivered = pallet.delivered;
                  palletOrder.files = pallet.files;


                  palletOrder.items = [];

                  let palletOrderItem = {};
                  palletOrderItem.itemPalletId = pallet.item_pallet_id;
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  // palletOrderItem.delivered = pallet.delivered;
                  palletOrderItem.deliveredQuantity = pallet.delivered_quantity;
                  palletOrderItem.returnedQuantity = pallet.returned_quantity;
                  palletOrderItem.returnedQuantityToStock = pallet.returned_quantity_to_stock;
                  palletOrderItem.returnedQuantityToStockStatus = pallet.returned_quantity_to_stock_status;
                  palletOrderItem.returnedQuantityApprovedRec = pallet.returned_quantity_approved_rec;
                  palletOrderItem.returnedQuantityApprovedSup = pallet.returned_quantity_approved_sup;
                  palletOrderItem.deliveryStatus = pallet.delivery_status;

                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  palletOrder.items.push(palletOrderItem);
                  palletOrder.orderWeight += Number(pallet.item_total_weight);
                  // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  palletOrder.orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );

                  palletObj.items.push(palletOrder);
                  palletObj.weight += Number(palletOrder.orderWeight);
                }
                checkRoute[0].pallets.push(palletObj);
              }
            } else {
              let newRouteObj = {};
              newRouteObj.route_status_id = pallet.route_status_id;
              newRouteObj.pallets = [];

              // new pallet
              let palletObj = {};
              palletObj.id = pallet.pallet_builder_id;
              palletObj.title = pallet.title;
              palletObj.pallet_id = pallet.pallet_id;
              palletObj.weight = 0;
              palletObj.items = [];

              if (pallet.internal_id) {
                let palletOrder = {};
                palletOrder.id = idx + "" + 1;
                palletOrder.rank = pallet.rank;
                palletOrder.customerInternalId = pallet.customer_internal_id;

                palletOrder.customerName = pallet.customer_name;
                palletOrder.notes = pallet.notes;
                palletOrder.received_by = pallet.received_by;
                palletOrder.delivery_discount_req_status = pallet.delivery_discount_req_status;
                palletOrder.delivery_discount_auth_by = pallet.delivery_discount_auth_by;
                palletOrder.payment_req_status = pallet.payment_req_status;
                palletOrder.payment_auth_by = pallet.payment_auth_by;
                palletOrder.signature = pallet.signature;

                palletOrder.invoiceInternalId = pallet.inv_internal_id;
                palletOrder.palletOrderID = pallet.pallet_order_id;
                // palletOrder.cashApproved = pallet.cash_approved;
                palletOrder.customerProjectId = pallet.customer_project_id;
                palletOrder.shipZip = pallet.ship_zip;
                palletOrder.customerOpeningTime = pallet.customer_opening_time;
                palletOrder.terms = pallet.terms;
                palletOrder.shipAddress1 = pallet.ship_address1;

                palletOrder.internalId = pallet.internal_id;
                palletOrder.invInternalId = pallet.inv_internal_id;
                palletOrder.invoiceNo = pallet.invoice_no;
                palletOrder.saleOrderNo = pallet.sale_order_no;

                palletOrder.orderWeight = 0;
                palletOrder.orderAmount = 0;

                palletOrder.items = [];

                let palletOrderItem = {};
                palletOrderItem.itemPalletId = pallet.item_pallet_id;
                palletOrderItem.itemId = pallet.item_id;
                palletOrderItem.itemName = pallet.item_name;
                palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                palletOrderItem.itemWeight = pallet.item_weight;
                palletOrderItem.quantity = pallet.quantity;
                palletOrderItem.deliveredQuantity = pallet.delivered_quantity;
                palletOrderItem.returnedQuantity = pallet.returned_quantity;
                palletOrderItem.returnedQuantityToStock = pallet.returned_quantity_to_stock;
                palletOrderItem.returnedQuantityToStockStatus = pallet.returned_quantity_to_stock_status;
                palletOrderItem.returnedQuantityApprovedRec = pallet.returned_quantity_approved_rec;
                palletOrderItem.returnedQuantityApprovedSup = pallet.returned_quantity_approved_sup;
                palletOrderItem.deliveryStatus = pallet.delivery_status;

                palletOrderItem.unitPrice = pallet.unit_price;

                palletOrderItem.grossAmountIncludingTax =
                  pallet.gross_amount_incl_tax;
                palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                palletOrder.items.push(palletOrderItem);
                palletOrder.orderWeight += Number(pallet.item_total_weight);
                // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));;
                palletOrder.orderAmount += Number(pallet.gross_amount_incl_tax);


                palletOrder.amountReceivedCash = pallet.amount_received_cash;
                palletOrder.amountReceivedCard = pallet.amount_received_card;
                palletOrder.amountReceivedAccount = pallet.amount_received_account;
                palletOrder.amountReceivedCashPost = pallet.amount_received_cash_post;
                palletOrder.amountReceivedCardPost = pallet.amount_received_card_post;
                palletOrder.amountReceivedAccountPost = pallet.amount_received_account_post;
                palletOrder.delivered = pallet.delivered;
                palletOrder.files = pallet.files;

                palletObj.items.push(palletOrder);
                palletObj.weight += Number(palletOrder.orderWeight);
              }

              newRouteObj.pallets.push(palletObj);

              route_pallets1.push(newRouteObj);
            }
          });
          // console.log(route_pallets1);
          setRoutePallets(route_pallets1);
        } else {
          setType("error");
          setLoadMessage("Pallet builder not saved for selected route ....");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetReportData = () => {

    setDriverSheetData([]);
    setGoodsReturnData([]);
    setFinancialSummaryData([]);
    setCashUpData([]);
    setStockDiscrepanciesData([]);
    setAmountDiscrepanciesData([]);
    localStorage.removeItem('disabledRoutes')
  }
  const printDriverSheet = () => {
    var routeNo = 1;

    resetReportData();

    let driverSheetData1 = [];
    const initialPaymentDataPost = {};

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
        routePallet.netsuite_route_id = route[0].netsuite_route_id;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      let driverSheetDataTemp = {};

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });
      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r.internalId == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1]['orderAmount'] = (
            Number(rows[rows.length - 1]['orderAmount']) + Number(order.orderAmount)
          ).toFixed(2);
        } else {
          let row1 = {};
          row1.rank = order.rank;
          row1.customerName = order.customerName;
          row1.customerProjectId = order.customerProjectId;
          row1.palletOrderID = order.palletOrderID;
          row1.delivered = order.delivered;
          row1.notes = order.notes;
          row1.received_by = order.received_by;
          row1.delivery_discount_req_status = order.delivery_discount_req_status;
          row1.delivery_discount_auth_by = order.delivery_discount_auth_by;
          row1.payment_req_status = order.payment_req_status;
          row1.payment_auth_by = order.payment_auth_by;
          row1.signature = order.signature;
          row1.files = (order.files === null || order.files === undefined) ? [] : JSON.parse(order.files);

          // row1.files = order.files;
          row1.routeStatusID = routePallet.route_status_id;

          row1.customerInternalId = order.customerInternalId;
          row1.internalId = order.internalId;
          row1.invoiceInternalId = order.invoiceInternalId;
          // row1.cashApproved = order.cashApproved;
          row1.invoice_no = order.invoiceNo
            ? order.invoiceNo
            : order.saleOrderNo;
          row1.orderAmount = order.orderAmount.toFixed(2);
          row1.amountReceivedCash = order.amountReceivedCash
            ? order.amountReceivedCash.toFixed(2)
            : 0;
          
          row1.amountReceivedCashPost = order.amountReceivedCashPost !== null && order.amountReceivedCashPost !== undefined
            ? Number(order.amountReceivedCashPost).toFixed(2)
            : null;
          
          row1.amountReceivedCard = order.amountReceivedCard
            ? order.amountReceivedCard.toFixed(2)
            : 0;
          
            row1.amountReceivedCardPost = order.amountReceivedCardPost !== null && order.amountReceivedCardPost !== undefined
            ? Number(order.amountReceivedCardPost).toFixed(2)
            : null;
          
          // row1.amountReceivedCardPost = order.amountReceivedCardPost
          //   ? order.amountReceivedCardPost.toFixed(2)
          //   : null;
          row1.amountReceivedAccount = order.amountReceivedAccount
            ? order.amountReceivedAccount.toFixed(2)
            : 0;

          row1.amountReceivedAccountPost = order.amountReceivedAccountPost !== null && order.amountReceivedAccountPost !== undefined
            ? Number(order.amountReceivedAccountPost).toFixed(2)
            : null;
          // row1.amountReceivedAccountPost = order.amountReceivedAccountPost
          //   ? order.amountReceivedAccountPost.toFixed(2)
          //   : null;


          initialPaymentDataPost[order.palletOrderID] = {
            order_pallet_id: order.palletOrderID,
            amount_received_cash_post: order.amountReceivedCashPost,
            amount_received_card_post: order.amountReceivedCardPost,
            amount_received_account_post: order.amountReceivedAccountPost,

          };
          rows.push(row1);
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var netsuiteRouteId = routePallet.netsuite_route_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var terminal = routePallet.selectedRoute.terminal_id;
      var payments_posted = routePallet.selectedRoute.payments_posted
      const postingDateInitial = routePallet.selectedRoute.posting_date ? moment(routePallet.selectedRoute.posting_date).format('YYYY-MM-DD') : deliveryDate

      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        terminal: terminal,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        netsuiteRouteId: netsuiteRouteId,
        payments_posted: payments_posted,
        postingDateInitial: postingDateInitial
      };

      driverSheetData1.push(data);
      routeNo = routeNo + 1;
    });

    setPaymnetDataPost(initialPaymentDataPost)

    if (selectedDriverRoute.length) {
      let filteredDriverSheetData = driverSheetData1?.filter((data) => {
        const routeStatusIDString = String(data.routeStatusID);
        const selectedRouteStrings = selectedDriverRoute.map(String);
        return selectedRouteStrings.includes(routeStatusIDString);
      });
      setDriverSheetData(filteredDriverSheetData);
    } else {
      setDriverSheetData(driverSheetData1);
    }
  };


  const printGoodsReturn = () => {
    var routeNo = 1;

    resetReportData();

    let goodsReturnData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    const initialDeliveryQuantities = {};

    routePallets.map((routePallet) => {
      let goodsReturnDataTemp = {};
      let rows = [];
      let return_rows_count = 1;
      routePallet.pallets.map((rp, idx) => {

        rp.items.map((order, idx1) => {
          order.items.map((item, idx2) => {
            if (item.itemId != 'Delivery Charge' && item.deliveredQuantity != item.quantity) // item.deliveredQuantity != null && 
            {
              let row1 = {};
              row1.rank = return_rows_count++;
              row1.customerName = order.customerName;
              row1.customerInternalId = order.customerInternalId;
              row1.customerProjectId = order.customerProjectId;

              row1.invoiceInternalId = order.invoiceInternalId;
              row1.invoiceNo = order.invoiceNo;
              row1.itemPalletId = item.itemPalletId;
              row1.returnedQuantity = item.returnedQuantity;
              row1.returnedQuantityToStock = item.returnedQuantityToStock;
              row1.returnedQuantityToStockStatus = item.returnedQuantityToStockStatus;
              row1.returnedQuantityApprovedRec = item.returnedQuantityApprovedRec;
              row1.returnedQuantityApprovedSup = item.returnedQuantityApprovedSup;
              row1.itemId = item.itemId;
              row1.itemName = item.itemName;
              row1.quantity = Number(item.quantity);
              row1.orgReturnedQuantity = Number(item.quantity) - Number(item.deliveredQuantity);
              if (item.deliveryStatus)
                row1.status = deliveryStatuses.find(obj => obj.id == item.deliveryStatus)?.title;
              else
                row1.status = "";

              initialDeliveryQuantities[item.itemPalletId] = {
                item_pallet_id: item.itemPalletId,
                returned_quantity: item.returnedQuantity || 0,
                returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                _quantity_status: item.returnedQuantityStatus,
              };

              rows.push(row1);
            }
          })
        })
      })

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var files = routePallet.selectedRoute.files == null ? [] : JSON.parse(routePallet.selectedRoute.files);
      var notes = routePallet.selectedRoute.notes == null ? '' : routePallet.selectedRoute.notes;
      var driverID = routePallet.selectedRoute.driver_id == null ? 0 : routePallet.selectedRoute.driver_id;
      var fuelCardReturn = routePallet.selectedRoute.fuel_card_returned == null ? 0 : routePallet.selectedRoute.fuel_card_returned;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var driver_returns_notes = routePallet.selectedRoute.driver_returns_notes == null ? '' : routePallet.selectedRoute.driver_returns_notes;
      var driver_returns_files = routePallet.selectedRoute.driver_returns_files == null ? [] : JSON.parse(routePallet.selectedRoute.driver_returns_files);
  

      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        notes: notes,
        files: files,
        newFiles: [],
        driverRoute: driverRoute,
        routeStatusID: routeStatusID,
        driverID: driverID,
        fuelCardReturn: fuelCardReturn,
        driver_returns_notes : driver_returns_notes,
        driver_returns_files : driver_returns_files
      };

      if (rows.length > 0) {

        goodsReturnData1.push(data);
        routeNo = routeNo + 1;
      }
    });


    setDeliveryQuantities(initialDeliveryQuantities);

    if (selectedDriverRoute.length) {
      let filteredGoodsReturnData = goodsReturnData1?.filter((data) => {
        const routeStatusIDString = String(data.routeStatusID);
        const selectedRouteStrings = selectedDriverRoute.map(String);
        return selectedRouteStrings.includes(routeStatusIDString);
      });
      setGoodsReturnData(filteredGoodsReturnData);
    } else {
      setGoodsReturnData(goodsReturnData1);
    }
  };

  const printCashUp = () => {
    var routeNo = 1;

    resetReportData();

    let cashUpData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      let cashUpDataTemp = {};

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r.internalId == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1]["orderAmount"] = (
            Number(rows[rows.length - 1]["orderAmount"]) + Number(order.orderAmount)
          ).toFixed(2);
        } else {
          let row1 = {};
          row1.rank = order.rank;
          row1.customerName = order.customerName;
          row1.customerInternalId = order.customerInternalId;
          row1.internalId = order.internalId;
          row1.invoiceInternalId = order.invoiceInternalId;
          row1.customerProjectId = order.customerProjectId;
          row1.palletOrderID = order.palletOrderID;
          row1.delivered = order.delivered;
          // row1.cashApproved = order.cashApproved;
          row1.invoice_no = order.invoiceNo
            ? order.invoiceNo
            : order.saleOrderNo;
          row1.orderAmount = order.orderAmount.toFixed(2);
          row1.amountReceivedCash = order.amountReceivedCash
            ? order.amountReceivedCash.toFixed(2)
            : "    ";
          row1.amountReceivedCard = order.amountReceivedCard
            ? order.amountReceivedCard.toFixed(2)
            : "    ";
          row1.amountReceivedAccount = order.amountReceivedAccount
            ? order.amountReceivedAccount.toFixed(2)
            : "    ";
          rows.push(row1);
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;
      var terminal = routePallet.selectedRoute.terminal_id;
      var dc_notes = routePallet.selectedRoute.dc_notes == null ? '' : routePallet.selectedRoute.dc_notes;
      var dc_fifty_notes = routePallet.selectedRoute.dc_fifty_notes == null ? '' : routePallet.selectedRoute.dc_fifty_notes;
      var dc_twenty_notes = routePallet.selectedRoute.dc_twenty_notes == null ? '' : routePallet.selectedRoute.dc_twenty_notes;
      var dc_ten_notes = routePallet.selectedRoute.dc_ten_notes == null ? '' : routePallet.selectedRoute.dc_ten_notes;
      var dc_five_notes = routePallet.selectedRoute.dc_five_notes == null ? '' : routePallet.selectedRoute.dc_five_notes;
      var dc_files = routePallet.selectedRoute.dc_files == null ? '' : JSON.parse(routePallet.selectedRoute.dc_files);
      var dc_change_total = routePallet.selectedRoute.dc_change_total == null ? '' : routePallet.selectedRoute.dc_change_total;


      var dc_one_pennies_coins = routePallet.selectedRoute.dc_one_pennies_coins == null ? '' : routePallet.selectedRoute.dc_one_pennies_coins;
      var dc_two_pennies_coins = routePallet.selectedRoute.dc_two_pennies_coins == null ? '' : routePallet.selectedRoute.dc_two_pennies_coins;
      var dc_five_pennies_coins = routePallet.selectedRoute.dc_five_pennies_coins == null ? '' : routePallet.selectedRoute.dc_five_pennies_coins;
      var dc_ten_pennies_coins = routePallet.selectedRoute.dc_ten_pennies_coins == null ? '' : routePallet.selectedRoute.dc_ten_pennies_coins;
      var dc_twenty_pennies_coins = routePallet.selectedRoute.dc_twenty_pennies_coins == null ? '' : routePallet.selectedRoute.dc_twenty_pennies_coins;
      var dc_fifty_pennies_coins = routePallet.selectedRoute.dc_fifty_pennies_coins == null ? '' : routePallet.selectedRoute.dc_fifty_pennies_coins;
      var dc_one_pound_coins = routePallet.selectedRoute.dc_one_pound_coins == null ? '' : routePallet.selectedRoute.dc_one_pound_coins;
      var dc_two_pound_coins = routePallet.selectedRoute.dc_two_pound_coins == null ? '' : routePallet.selectedRoute.dc_two_pound_coins;

      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        terminal: terminal,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        cashReceived: cashReceived,
        cardReceived: cardReceived,
        dc_notes: dc_notes,
        dc_fifty_notes: dc_fifty_notes,
        dc_twenty_notes: dc_twenty_notes,
        dc_ten_notes: dc_ten_notes,
        dc_five_notes: dc_five_notes,
        dc_files: dc_files,
        dc_change_total: dc_change_total,
        dc_one_pennies_coins: dc_one_pennies_coins,
        dc_two_pennies_coins: dc_two_pennies_coins,
        dc_five_pennies_coins: dc_five_pennies_coins,
        dc_ten_pennies_coins: dc_ten_pennies_coins,
        dc_twenty_pennies_coins: dc_twenty_pennies_coins,
        dc_fifty_pennies_coins: dc_fifty_pennies_coins,
        dc_two_pound_coins: dc_two_pound_coins,
        dc_one_pound_coins: dc_one_pound_coins
      };

      cashUpData1.push(data);
      routeNo = routeNo + 1;
    });

    if (selectedDriverRoute.length) {
      let filteredCashUpData = cashUpData1?.filter((data) => {
        const routeStatusIDString = String(data.routeStatusID);
        const selectedRouteStrings = selectedDriverRoute.map(String);
        return selectedRouteStrings.includes(routeStatusIDString);
      });
      setCashUpData(filteredCashUpData);
    } else {
      setCashUpData(cashUpData1);
    }
  };

  const printFinancialSummary = () => {
    var routeNo = 1;

    resetReportData();

    let financialSummaryData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      let financialSummaryDataTemp = {};

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });
      let internalId = 0;

      all_orders.map((order) => {

        // Check if the order split in multiple lines  (checking id with preivous id)
        if (internalId != order.internalId) {
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
        internalId = order.internalId;

      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";

      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;

      // var dc_notes = routePallet.selectedRoute.dc_notes == null ? 0 : routePallet.selectedRoute.dc_notes;
      var dc_fifty_notes = routePallet.selectedRoute.dc_fifty_notes == null ? 0 : routePallet.selectedRoute.dc_fifty_notes;
      var dc_twenty_notes = routePallet.selectedRoute.dc_twenty_notes == null ? 0 : routePallet.selectedRoute.dc_twenty_notes;
      var dc_ten_notes = routePallet.selectedRoute.dc_ten_notes == null ? 0 : routePallet.selectedRoute.dc_ten_notes;
      var dc_five_notes = routePallet.selectedRoute.dc_five_notes == null ? 0 : routePallet.selectedRoute.dc_five_notes;
      // var dc_files = routePallet.selectedRoute.dc_files == null ? 0 : JSON.parse(routePallet.selectedRoute.dc_files);
      var dc_change_total = routePallet.selectedRoute.dc_change_total == null ? 0 : routePallet.selectedRoute.dc_change_total;


      var dc_one_pennies_coins = routePallet.selectedRoute.dc_one_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_one_pennies_coins;
      var dc_two_pennies_coins = routePallet.selectedRoute.dc_two_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_two_pennies_coins;
      var dc_five_pennies_coins = routePallet.selectedRoute.dc_five_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_five_pennies_coins;
      var dc_ten_pennies_coins = routePallet.selectedRoute.dc_ten_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_ten_pennies_coins;
      var dc_twenty_pennies_coins = routePallet.selectedRoute.dc_twenty_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_twenty_pennies_coins;
      var dc_fifty_pennies_coins = routePallet.selectedRoute.dc_fifty_pennies_coins == null ? 0 : routePallet.selectedRoute.dc_fifty_pennies_coins;
      var dc_one_pound_coins = routePallet.selectedRoute.dc_one_pound_coins == null ? 0 : routePallet.selectedRoute.dc_one_pound_coins;
      var dc_two_pound_coins = routePallet.selectedRoute.dc_two_pound_coins == null ? 0 : routePallet.selectedRoute.dc_two_pound_coins;


      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        route: routeNo,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        cashReceived: cashReceived,
        cardReceived: cardReceived,
        // dc_notes: dc_notes,
        dc_fifty_notes: dc_fifty_notes,
        dc_twenty_notes: dc_twenty_notes,
        dc_ten_notes: dc_ten_notes,
        dc_five_notes: dc_five_notes,
        // dc_files: dc_files,
        dc_change_total: dc_change_total,
        dc_one_pennies_coins: dc_one_pennies_coins,
        dc_two_pennies_coins: dc_two_pennies_coins,
        dc_five_pennies_coins: dc_five_pennies_coins,
        dc_ten_pennies_coins: dc_ten_pennies_coins,
        dc_twenty_pennies_coins: dc_twenty_pennies_coins,
        dc_fifty_pennies_coins: dc_fifty_pennies_coins,
        dc_two_pound_coins: dc_two_pound_coins,
        dc_one_pound_coins: dc_one_pound_coins
      };

      financialSummaryData1.push(data);
      routeNo = routeNo + 1;
    });

    // if (selectedDriverRoute.length) {
    //   let filteredFinancialSummaryData = financialSummaryData1?.filter((data) => {
    //     const routeStatusIDString = String(data.routeStatusID);
    //     const selectedRouteStrings = selectedDriverRoute.map(String);
    //     return selectedRouteStrings.includes(routeStatusIDString);
    //   });
    //   setFinancialSummaryData(filteredFinancialSummaryData);
    // } else 
    {
      setFinancialSummaryData(financialSummaryData1);
    }
  };


  const printStockDiscrepancies = () => {
    var routeNo = 1;

    resetReportData();

    let stockDiscrepanciesData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    const initialDeliveryQuantities = {};

    routePallets.map((routePallet) => {
      let stockDiscrepanciesDataTemp = {};
      let rows = [];
      let return_rows_count = 1;
      routePallet.pallets.map((rp, idx) => {

        rp.items.map((order, idx1) => {
          order.items.map((item, idx2) => {
            if (item.itemId != 'Delivery Charge' && item.deliveredQuantity != item.quantity)// && Number(item.quantity) - Number(item.deliveredQuantity) !== item.returnedQuantity) // item.deliveredQuantity != null && 
            {
              let row1 = {};
              row1.rank = return_rows_count++;
              row1.customerName = order.customerName;
              row1.customerInternalId = order.customerInternalId;
              row1.invoiceInternalId = order.invoiceInternalId;
              row1.customerProjectId = order.customerProjectId;
              row1.invoiceNo = order.invoiceNo;
              row1.itemPalletId = item.itemPalletId;
              row1.returnedQuantity = item.returnedQuantity; // Actual quantity that is returned to stock
              row1.returnedQuantityApprovedRec = item.returnedQuantityApprovedRec;
              row1.itemId = item.itemId;
              row1.itemName = item.itemName;
              row1.quantity = Number(item.quantity);
              row1.returnedQuantityToStockStatus = item.returnedQuantityToStockStatus;
              row1.returnedQuantityToStock = item.returnedQuantityToStock;
              // row1.quantityToBeReceived = Number(item.quantity) - Number(item.deliveredQuantity);

              row1.orgReturnedQuantity = Number(item.quantity) - Number(item.deliveredQuantity);

              if (item.deliveryStatus)
                row1.status = deliveryStatuses.find(obj => obj.id == item.deliveryStatus)?.title;
              else
                row1.status = "";

              initialDeliveryQuantities[item.itemPalletId] = {
                item_pallet_id: item.itemPalletId,
                returned_quantity: item.returnedQuantity || 0,
                returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                _quantity_status: item.returnedQuantityStatus,
              };

              rows.push(row1);
            }
          })
        })
      })

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";

      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        driverRoute: driverRoute,
        routeStatusID: routeStatusID,
      };

      if (rows.length > 0) {

        stockDiscrepanciesData1.push(data);
        routeNo = routeNo + 1;
      }
    });


    setDeliveryQuantities(initialDeliveryQuantities);

    if (selectedDriverRoute.length) {
      let filteredStockDiscrepanciesData = stockDiscrepanciesData1?.filter((data) => {
        const routeStatusIDString = String(data.routeStatusID);
        const selectedRouteStrings = selectedDriverRoute.map(String);
        return selectedRouteStrings.includes(routeStatusIDString);
      });
      setStockDiscrepanciesData(filteredStockDiscrepanciesData);
    } else {
      setStockDiscrepanciesData(stockDiscrepanciesData1);
    }
  };


  const printAmountDiscrepancies = () => {
    var routeNo = 1;

    resetReportData();

    let amountDiscrepanciesData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      let amountDiscrepanciesDataTemp = {};

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      let internalId = 0;
      all_orders.map((order) => {

        // Check if the order split in multiple lines  (checking id with preivous id)
        if (internalId != order.internalId) {
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
        internalId = order.internalId;

      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";

      var cashReceived = routePallet.cash_received;
      var cardReceived = routePallet.card_received;

      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;

      if (cashReceived != totalCash || cardReceived != totalCard) {
        let data = {
          formatted_date: formatted_date,
          driver: driver,
          truck: truck,
          route: routeNo,
          driverRoute: driverRoute,
          totalCash: totalCash,
          totalCard: totalCard,
          cashReceived: cashReceived,
          cardReceived: cardReceived,
          totalAccount: totalAccount,
          routeStatusID: routeStatusID,
        };

        amountDiscrepanciesData1.push(data);
      }
      routeNo = routeNo + 1;
    });

    // if (selectedDriverRoute.length) {
    //   let filteredAmountDiscrepanciesData = amountDiscrepanciesData1?.filter((data) => {
    //     const routeStatusIDString = String(data.routeStatusID);
    //     const selectedRouteStrings = selectedDriverRoute.map(String);
    //     return selectedRouteStrings.includes(routeStatusIDString);
    //   });
    //   setAmountDiscrepanciesData(filteredAmountDiscrepanciesData);
    // } else 
    {
      setAmountDiscrepanciesData(amountDiscrepanciesData1);
    }
  };

  const getPaymentAuthorizer = async () => {
    
    try {
      const res = await Auth.fetch("/driverapp/get-payment-authorizers");
      if (res?.ack) {
        setPaymentAuthorizer(res?.payment_authorizers)
      } else {
        setType('error')
        setLoadMessage(res?.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getPaymentAuthorizer()
  },[])

  return (
    <>
    <h2 style={{marginBottom: '10px', marginTop: 0}}>View Reports</h2>
      <div>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "flex-end", display: "flex" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {/* <DatePicker
                value={deliveryDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setDeliveryDate(date);
                  setRoutes([]);
                  resetReportData();
                }}
                className={classes.dateField}
              /> */}
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
                  setRoutes([]);
                  resetReportData();
              }}
              placeholder="Select Date"
              />
              {/* <FormButton type="noIcon" onClick={loadRoutes}>
                Load Routes
              </FormButton> */}
              <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>

            </Box>
          </Grid>
        </Grid>
        <br />

        {routes.length > 0 && (

          <>
            {
              (!includesRouting && !includesWareHouse && !includesWareHouseManager) && (
                <AntButton
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    setActiveButton('Financial Summary');
                    printFinancialSummary();
                  }}
                  // style={activeButton === 'Financial Summary' ? { backgroundColor: 'green' } : null}
                  style={{ backgroundColor: activeButton === 'Financial Summary' ? 'green' : null, marginRight: '5px' }}

                >
                  {" "}
                  Financial Summary
                </AntButton>
              )
            }

            {
              UserType == 1 && (
                <AntButton
                  size="large"
                  type="primary"
                  onClick={(e) => {
                    setActiveButton('Amount Dis');
                    printAmountDiscrepancies();
                  }}
                  // style={{activeButton === 'Amount Dis' ? { backgroundColor: 'green' } : null}}
                  style={{ backgroundColor: activeButton === 'Amount Dis' ? 'green' : null }}

                >
                  {" "}
                  Amount Discrepancies
                </AntButton>
              )
            }

            <Divider />
            <div className={classes.selectStyle}>
              <Select
                mode="multiple"
                style={{
                  width: "50%",
                  margin: "2px",
                  fontSize: '14px'
                }}
                allowClear
                placeholder="Select Route"
                onChange={(value) => {
                  setSelectedDriverRoute(value);
                  resetReportData()
                  setActiveButton('')
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {routes.slice().sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
                  <Option key={item.id} value={item.id}>
                    {/* {item.title + " (" + item.code + ")" + item.registration_number} */}
                    {`${item.title} (${item.code}) - ${item.registration_number} - ${item.driver_name} `}
                  </Option>
                ))}
              </Select>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px" }}>
                <AntButton
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    setActiveButton('Cash Up');
                    printCashUp();
                  }}
                  style={activeButton === 'Cash Up' ? { backgroundColor: 'green' } : null}
                >
                  {" "}
                  Cash Up
                </AntButton>
                <AntButton
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    setActiveButton('DriverSheet');
                    printDriverSheet();
                  }}
                  style={activeButton === 'DriverSheet' ? { backgroundColor: 'green' } : null}
                >
                  {" "}
                  Driver Sheet
                </AntButton>
                <AntButton
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    setActiveButton('Goods');
                    printGoodsReturn();
                  }}
                  style={activeButton === 'Goods' ? { backgroundColor: 'green' } : null}
                >
                  {" "}
                  Goods Return
                </AntButton>
                {
                  (UserType == 1 || roles[0].role_id == 4) && (
                    <>
                      <AntButton
                        type="primary"
                        size="large"
                        onClick={(e) => {
                          setActiveButton('Stock');
                          printStockDiscrepancies();
                        }}
                        style={activeButton === 'Stock' ? { backgroundColor: 'green' } : null}
                      >
                        {" "}
                        Stock Discrepancies
                      </AntButton>
                    </>
                  )
                }
              </div>
            </div>
          </>
        )}
        <hr />
        {/* refactor later for rendering  */}
        {/* {driverSheetData.length > 0 && <DriverSheetReport driverSheetData={driverSheetData} setDriverSheetData={setDriverSheetData} />} */}
        {driverSheetData.length > 0 && activeButton === 'DriverSheet' ? (<DriverSheetReport driverSheetData={driverSheetData} setDriverSheetData={setDriverSheetData} paymentDataPost1={paymnetDataPost} paymentAuthorizer={paymentAuthorizer}  />) : (activeButton === 'DriverSheet' && !driverSheetData.length) ? (<Result status="404" subTitle="Sorry, no data exists." />) : <></>}

        {/* {cashUpData.length > 0  && activeButton === 'Cash Up' ? <CashUpReport cashUpData={cashUpData} setCashUpData={setCashUpData} /> : <Result status="404" subTitle="Sorry, no data exists."/>} */}
        {cashUpData.length > 0 && activeButton === 'Cash Up' ? (<CashUpReport cashUpData={cashUpData} setCashUpData={setCashUpData} />) : (activeButton === 'Cash Up' && !cashUpData.length) ? (<Result status="404" subTitle="Sorry, no data exists." />) : <></>}
        {financialSummaryData.length > 0 && <FinancialSummaryReport financialSummaryData={financialSummaryData} deliveryDate={deliveryDate} />}
        {goodsReturnData.length > 0 && activeButton === 'Goods' ? (<GoodsReturnReport goodsReturnData={goodsReturnData} setGoodsReturnData={setGoodsReturnData} deliveryQuantities1={deliveryQuantities} />) : (activeButton === 'Goods' && !goodsReturnData.length) ? (<Result status="404" subTitle="Sorry, no data exists." />) : <></>}
        {/* {goodsReturnData.length > 0 && <GoodsReturnReport goodsReturnData={goodsReturnData} setGoodsReturnData={setGoodsReturnData} deliveryQuantities1={deliveryQuantities} />} */}
        {/* {stockDiscrepanciesData.length > 0 && <StockDiscrepanciesReport stockDiscrepanciesData={stockDiscrepanciesData} setStockDiscrepanciesData={setStockDiscrepanciesData} deliveryQuantities1={deliveryQuantities} />} */}
        {stockDiscrepanciesData.length > 0 && activeButton === 'Stock' ? (<StockDiscrepanciesReport stockDiscrepanciesData={stockDiscrepanciesData} setStockDiscrepanciesData={setStockDiscrepanciesData} deliveryQuantities1={deliveryQuantities} />) : (activeButton === 'Stock' && !stockDiscrepanciesData.length) ? (<Result status="404" subTitle="Sorry, no data exists." />) : <></>}
        {amountDiscrepanciesData.length > 0 && <AmountDiscrepanciesReport amountDiscrepanciesData={amountDiscrepanciesData} />}
        <Message type={type} msg={loadMessage} />
      </div>
    </>
  );
}
