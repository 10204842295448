import * as React from "react";

import {
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import Message from "../../Components/common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  availableDays,
  vehicleTypes,
  driverTypes,
  driverIssuesActions,
  driverIssuesTypes,
} from "../../Components/common/constants";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";

import moment from "moment";
import qs from "query-string";
import config from "./../../Components/config";

import { FormButton } from "../../Components/UiElements/UiElements";
import { Col, Row, Table, DatePicker as AntDate, Alert, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import { CheckCircleFilled, CloseCircleOutlined, EditFilled, ExclamationCircleOutlined, HistoryOutlined } from "@ant-design/icons";
import ContactHistoryModal from "../../Components/Modals/ContactHistoryModal";
import EditContactModal from "../../Components/Modals/EditContactModal";
import ResolveContactModal from "../../Components/Modals/ResolveContactModal ";
import { Button as UploadButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";


const useStyles = makeStyles((theme) => ({
 
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  dataSheet: {
    
  },
}));

const Auth = new AuthService();

export default function Driver(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [code, setCode] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [selectedDays, setSelectedDays] = React.useState([]);
  const [selectedOffDays, setSelectedOffDays] = React.useState([]);
  const [vehicleType, setVehicleType] = React.useState({ id: "", title: "" });
  const [driverType, setDriverType] = React.useState({ id: "", title: "" });

  const [codeError, setCodeError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [pinError, setPinError] = React.useState("");
  const [vehicleTypeError, setVehicleTypeError] = React.useState("");
  const [driverTypeError, setDriverTypeError] = React.useState("");

  const [bandError, setBandError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [messageKey, setMessageKey] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [history, setHistory] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const [issueType, setIssueType] = React.useState("");
  const [action, setAction] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [cost, setCost] = React.useState("");

  const currentDate = new Date()
  const sixMonthsFromNow = new Date(currentDate.setMonth(currentDate.getMonth() + 6));
  const [date, setDate] = React.useState(sixMonthsFromNow);

  const [tachoExpiry, setTachoExpiry] = React.useState(new Date());
  const [licenseExpiry, setLicenseExpiry] = React.useState(new Date());

  const [tachoInfo, setTachoInfo] = React.useState({
    date: new Date(),
    files: [],
    newFiles: [],
  });

  const [licenseInfo, setLicenseInfo] = React.useState({
    date: new Date(),
    files: [],
    newFiles: [],
  });
  const [cpcInfo, setCpcInfo] = React.useState({
    date: new Date(),
    files: [],
    newFiles: [],
  });


  const [allContactReminders, setAllContactReminders] = React.useState([]);
  const [completedContactReminders, setCompletedContactReminders] = React.useState([]);
  const [remainingContactReminders, setRemainingContactReminders] = React.useState([]);

  const [selectedRecord, setSelectedRecord] = React.useState("");

  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);


  const getData = async() => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/driver/driver?id=${id}`)
      if (res.ack) {
        setCode(res.driver.code);
        setFirstName(res.driver.first_name);
        setLastName(res.driver.last_name);
        setPin(res.driver.pin);
        setPhone(res.driver.phone);
        setEmail(res.driver.email);
        const vehicleType1 = vehicleTypes.find(
          (obj) => obj.id == res.driver.vehicle_type
        );
        setTachoExpiry(res?.driver?.tacho_card_expiry ? new Date(res?.driver?.tacho_card_expiry) : null)
        setTachoInfo((prev)=>({
          ...prev,
          date: res?.driver?.tacho_card_expiry ? new Date(res?.driver?.tacho_card_expiry) : null,
          files: res?.driver?.tacho_card_files ? JSON.parse(res?.driver?.tacho_card_files) : [],
          newFiles: [],
        }))
        setLicenseInfo((prev)=>({
          ...prev,
          date: res?.driver?.license_expiry ? new Date(res?.driver?.license_expiry) : null,
          files: res?.driver?.license_files ? JSON.parse(res?.driver?.license_files) : [],
          newFiles: [],
        }))
        setCpcInfo((prev)=>({
          ...prev,
          date: res?.driver?.cpc_expiry ? new Date(res?.driver?.cpc_expiry) : null,
          files: res?.driver?.cpc_files ? JSON.parse(res?.driver?.cpc_files) : [],
          newFiles: [],
        }))
        setLicenseExpiry(res?.driver?.license_expiry ? new Date(res?.driver?.license_expiry) : null)
        setVehicleType(vehicleType1);

        const driverType1 = driverTypes.find(
          (obj) => obj.id == res.driver.driver_type
        );
        setDriverType(driverType1);

        const inComplete = res?.contact_reminder?.filter((item)=> item?.status === 2)
        const complete = res?.contact_reminder?.filter((item)=> item?.status === 1)
        
        setAllContactReminders(res?.contact_reminder)
        setRemainingContactReminders(inComplete)
        setCompletedContactReminders(complete)

        const selected_days1 = res.seletected_days;
        const selected_off_days1 = res.seletected_off_days;
        const selected_days = [];
        const selected_off_days = [];

        if (selected_days1.length > 0) {
          selected_days1.map((day) => {
            let d = availableDays.find((obj) => obj.id == day.day);
            if (d.id) {
              selected_days.push(d);
            }
          });
          setSelectedDays(selected_days);
        }

        if (selected_off_days1.length > 0) {
          selected_off_days1.map((day) => {
            let d = availableDays.find((obj) => obj.id == day.day);
            if (d.id) {
              selected_off_days.push(d);
            }
          });
          setSelectedOffDays(selected_off_days);
        }

        // Auth.fetch(`/maintenance/maintenance-driver?driver_id=${id}`)
        //   .then((res) => {
        //     hideLoading(layoutDispatch);
        //     if (res.ack) {
        //       setHistory(res.maintenance);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      } else {
        message.error(res?.message)
      }
    } catch (err) {
      console.log(err);
      
    } finally {
      hideLoading(layoutDispatch)
    }

  }

  React.useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])


  React.useEffect(() => {
    if (id > 0) {
      getData()
    }
  }, []);

  const onClickCancel = () => {
    navigate("/drivers");
  };

  const getUploadUrl = async (file, id) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/drivers/" + id + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;

    const checkAndSetError = (condition, message) => {
      if (condition) {
        setType('error');
        setLoadMessage(`${message}${" ".repeat(Math.random() * 50)}`);
        return true;
      }
      return false;
    };

    if (
      checkAndSetError(isEmptyOrUndefined(code), 'Code is required') ||
      checkAndSetError(isEmptyOrUndefined(firstName), 'First Name is required') ||
      checkAndSetError(isEmptyOrUndefined(lastName), 'Last name is required') ||
      checkAndSetError(isEmptyOrUndefined(pin), 'Pin is required') ||
      checkAndSetError(isEmptyOrUndefined(email), 'Email is required') ||
      checkAndSetError(isEmptyOrUndefined(phone), 'Phone is required') ||
      checkAndSetError(isEmptyOrUndefined(vehicleType?.id), 'Max vehicle size is required') ||
      checkAndSetError(isEmptyOrUndefined(driverType?.id), 'Driver type is required') ||
      vehicleType?.id != '1' && checkAndSetError(isEmptyOrUndefined(tachoInfo?.date),'Tacho card expiry date is required') ||
      checkAndSetError(isEmptyOrUndefined(licenseInfo?.date), 'Licence expiry date is required') ||
      vehicleType?.id != '1' && checkAndSetError(isEmptyOrUndefined(cpcInfo?.date), 'Cpc date is required') ||
      checkAndSetError(isEmptyOrUndefined(date), 'Contact renewal reminder due date is required')
    ) {
      return;
    }

    const dataToSend = {
      code: code,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      vehicle_type: vehicleType?.id,
      email: email,
      driver_type: driverType?.id,
      selected_days: selectedDays,
      selected_off_days: selectedOffDays,
      pin: pin,
      due_date: date ? moment(date).format("YYYY-MM-DD") : null,
      tacho_card_expiry: tachoInfo?.date ? moment(tachoInfo?.date).format("YYYY-MM-DD") : null,
      tacho_card_files: tachoInfo?.files,
      license_expiry: licenseInfo?.date ? moment(licenseInfo?.date).format("YYYY-MM-DD") : null,
      license_files: licenseInfo?.files,
      cpc_expiry: cpcInfo?.date ? moment(cpcInfo?.date).format("YYYY-MM-DD") : null,
      cpc_files: cpcInfo?.files,
      id: id
    };
    if (id > 0) {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch("/driver/driver", {
          method: "PUT",
          body: JSON.stringify(dataToSend),
        })
        if (response.ack === true) {
          const uploadPromises = []
          if (tachoInfo.newFiles.length > 0) {
            tachoInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => {
                    console.error("Error uploading file:", error)
                    setType('error')
                    setLoadMessage("Error uploading file: " + str);
                  })
              );
            });
          }
          if (licenseInfo.newFiles.length > 0) {
            licenseInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => {
                    console.error("Error uploading file:", error)
                    setType('error')
                    setLoadMessage("Error uploading file: " + str);
                  })
              );
            });
          }
          if (cpcInfo.newFiles.length > 0) {
            cpcInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => {
                    console.error("Error uploading file:", error)
                    setType('error')
                    setLoadMessage("Error uploading file: " + str);
                  })
              );
            });
          }
          await Promise.all(uploadPromises);
          hideLoading(layoutDispatch);
          navigate("/drivers");
        } else {
          hideLoading(layoutDispatch);
          setType('error')
          setLoadMessage(response?.message)
        }

      } catch (error) {
        console.log(error);
        hideLoading(layoutDispatch);

      }


    } else {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch("/driver/driver", {
          method: "POST",
          body: JSON.stringify(dataToSend),
        })
        if (response.ack === true) {
          const uploadPromises = []
          if (tachoInfo.newFiles.length > 0) {
            tachoInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, response?.record_id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (licenseInfo.newFiles.length > 0) {
            licenseInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, response?.record_id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (cpcInfo.newFiles.length > 0) {
            cpcInfo.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, response?.record_id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          await Promise.all(uploadPromises);
          hideLoading(layoutDispatch);
          navigate("/drivers");
        } else {
          setType('error')
          setLoadMessage(response?.message)
          hideLoading(layoutDispatch);
        }
      } catch (err) {
        console.log(err);
        hideLoading(layoutDispatch);
      }
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (!(id > 0)) {
  //     if (code === "") {
  //       setCodeError("Required");
  //       return;
  //     } else {
  //       setCodeError("");
  //     }
  //   }

  //   if (firstName === "") {
  //     setFirstNameError("Required");
  //     return;
  //   } else {
  //     setFirstNameError("");
  //   }

  //   if (lastName === "") {
  //     setLastNameError("Required");
  //     return;
  //   } else {
  //     setLastNameError("");
  //   }

  //   if (pin === "") {
  //     setPinError("Required");
  //     return;
  //   } else {
  //     setPinError("");
  //   }

  //   if (email === "") {
  //     setEmailError("Required");
  //     return;
  //   } else {
  //     setEmailError("");
  //   }

  //   if (phone === "") {
  //     setPhoneError("Required");
  //     return;
  //   } else {
  //     setPhoneError("");
  //   }

  //   if (
  //     vehicleType == undefined ||
  //     vehicleType.id == undefined ||
  //     vehicleType.id == ""
  //   ) {
  //     setVehicleTypeError("Required");
  //     return;
  //   } else {
  //     setVehicleTypeError("");
  //   }

  //   if (
  //     driverType == undefined ||
  //     driverType.id == undefined ||
  //     driverType.id == ""
  //   ) {
  //     setDriverTypeError("Required");
  //     return;
  //   } else {
  //     setDriverTypeError("");
  //   }

  //   if (id > 0) {
  //     showLoading(layoutDispatch);
  //     Auth.fetch("/driver/driver", {
  //       method: "PUT",
  //       body: JSON.stringify({
  //         first_name: firstName,
  //         last_name: lastName,
  //         phone: phone,
  //         vehicle_type: vehicleType?.id,
  //         driver_type: driverType?.id,
  //         selected_days: selectedDays,
  //         selected_off_days: selectedOffDays,
  //         id: id,
  //         email: email,
  //         pin: pin,
  //         tacho_card_expiry: tachoInfo?.date ? moment(tachoInfo?.date).format("YYYY-MM-DD") : null,
  //         license_expiry: licenseInfo?.date ? moment(licenseInfo?.date).format("YYYY-MM-DD") : null,
  //         cpc_expiry: cpcInfo?.date ? moment(cpcInfo?.date).format("YYYY-MM-DD") : null,
  //       }),
  //     })
  //       .then((res) => {
  //         hideLoading(layoutDispatch);
  //         if (res.ack === true) {
  //           setType("success");
  //           setLoadMessage(res.message);
  //           setTimeout(() => {
  //             navigate("/drivers");
  //           }, 1000);
  //           setLoading(false);
  //         } else {
  //           setType("error");
  //           setLoadMessage(res.message);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     setLoading(true);
  //     showLoading(layoutDispatch);
  //     Auth.fetch("/driver/driver", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         code: code,
  //         first_name: firstName,
  //         last_name: lastName,
  //         phone: phone,
  //         vehicle_type: vehicleType?.id,
  //         email: email,
  //         driver_type: driverType?.id,
  //         selected_days: selectedDays,
  //         selected_off_days: selectedOffDays,
  //         pin: pin,
  //         due_date: date ? moment(date).format("YYYY-MM-DD") : null,
  //         tacho_card_expiry: tachoInfo?.date ? moment(tachoInfo?.date).format("YYYY-MM-DD") : null,
  //         license_expiry: licenseInfo?.date ? moment(licenseInfo?.date).format("YYYY-MM-DD") : null,
  //         cpc_expiry: cpcInfo?.date ? moment(cpcInfo?.date).format("YYYY-MM-DD") : null,
  //       }),
  //     })
  //       .then((res) => {
  //         hideLoading(layoutDispatch);
  //         if (res.ack === true) {
  //           setType("success");
  //           setLoadMessage(res.message);
  //           setTimeout(() => {
  //             navigate("/drivers");
  //           }, 1000);
  //           setLoading(false);
  //         } else {
  //           setType("error");
  //           setLoadMessage(res.message);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const onChangeDays = (event, values) => {
    console.log(selectedOffDays);
    let check = selectedOffDays.filter(
      (s) => s.id == values[values.length - 1].id
    );
    if (check.length > 0) {
      setType("error");
      setMessageKey(!messageKey);
      setLoadMessage("Already selected as off day");
      // alert('Already selected as off day');
    } else setSelectedDays(values);
  };

  const onChangeOffDays = (event, values) => {
    console.log(selectedDays);
    let check = selectedDays.filter(
      (s) => s.id == values[values.length - 1].id
    );
    if (check.length > 0) {
      setType("error");
      setMessageKey(!messageKey);
      setLoadMessage("Already selected as available day");
      // alert('Already selected as available day');
    } else setSelectedOffDays(values);
  };

  const onChangeVehicleType = (event, values) => {
    setVehicleType(values);
  };
  const onChangeDriverType = (event, values) => {
    setDriverType(values);
  };

  const [open, setOpen] = React.useState(false);
  const [filesOpen, setFilesOpen] = React.useState(false);

  const handleFilesOpen = (row) => {
    if (row.files) {
      console.log(JSON.parse(row.files));
      setSelectedFiles(JSON.parse(row.files));
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const handleClickOpen = () => {
    setOpen(true);
    setFiles([]);
    setSelectedFiles([]);
    setIssueType("");
    setAction("");
    setNotes("");
    setCost("");
  };


  const handleSubmitImage = (e) => {
    e.preventDefault();

    console.log("inside handle subbmit");

    let acceptedFiles = e.target.files;

    // console.log(acceptedFiles.length)

    var i = 0;
    for (let file of acceptedFiles) {
      // var fileExt = file.name.split(".").pop();
      // let temp = Math.random().toString(36).slice(5);
      // let file_name =
      //   file.name.substring(0, file.name.length - 3) +
      //   "-" +
      //   temp +
      //   "." +
      //   fileExt; // uuidv4();
        const randomString = Math.random().toString(36).substring(2, 10);
        const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;

      let params = {
        bucket: config.tms_uploads_bucket,
        ContentType: file.type,
        // Key: file_name + "." + fileExt,
        Key: config.env + "/drivers/" + newFileName,
      };
      setLoadMessage("");
      fetch(`${config.domain}/generate-put-url?${qs.stringify(params)}`, {
        headers: {
          "Content-Type": params.ContentType,
        },
      })
        .then((response) => response.json())
        // eslint-disable-next-line no-loop-func
        .then((res) => {
          setType("success");
          setLoadMessage("");
          fetch(res.putURL, {
            method: "put",
            body: file,
            headers: {
              "Content-Type": params.ContentType,
            },
          })
            .then((response) => response)
            .then((data) => {
              if (data.status === 200) {
                console.log("uploaded !!");
              }
            });
          let files1 = files;
          files1.push(newFileName);
          setFiles(files1);
          setLoadMessage("File Uploaded !!");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleSubmitHistory = (e) => {
    e.preventDefault();

    let rand = Math.random() * 50; // for unique message each time
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    if (notes === "") {
      setType("error");
      setLoadMessage("Please add notes" + str);
      return;
    } else {
      setLoadMessage("");
    }

    if (issueType === "") {
      setType("error");
      setLoadMessage("Please select type" + str);
      return;
    } else {
      setLoadMessage("");
    }

    setLoading(true);
    showLoading(layoutDispatch);
    let reqBody = {
      driver_id: id,
      notes: notes,
      issue_type: issueType,
      action: action,
      cost: cost,
      files: files,
    };

    Auth.fetch("/maintenance/maintenance-driver", {
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack === true) {
          setType("success");
          setLoadMessage(res.message);
          window.location.reload();

          setLoading(false);
        } else {
          setType("error");
          setLoadMessage(res.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const driverColumns = [
    {
      title: 'Type',
      render: (record) => {
        return record.issue_type
        ? driverIssuesTypes.filter(
          (obj) => obj.id == record.issue_type
        )[0].title
        : ""
      }
    },
    {
      title: "Action",
      render: (record) => {
        return record.action
        ? driverIssuesActions.filter(
          (obj) => obj.id == record.action
        )[0].title
        : ""
      }
    },
    {
      title: 'Cost',
      width: 175,
      render: (record) => {
        return record?.cost
          ? "£" +
              Number(record?.cost).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
          : '';
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Updated By',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 300
    },
    {
      title: 'Updated At',
      render: (record) => {
        return record.createdAt
        ? moment(record.createdAt).format("YYYY-MM-DD H:mm:ss")
        : ""
      }
    },
    {
      title: 'Files',
      render: (record) => {
        return JSON.parse(record.files).length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        ) : (
          <Button
            disabled
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        )
      },
    },
  ];

  const getStatusInfo = (date) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference <= 30) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <HistoryOutlined />,
      };
    }
  };


  const remainingReminderColumns = [
    
    {
      title: 'Next Due',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      }

    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date }) => {
        const { color, text, icon } = getStatusInfo(moment(due_date, "YYYY-MM-DD"));
        return (
          <Tag color={color} icon={icon} style={{ marginLeft: '4px' }}>
            {text}
          </Tag>
        );
      },
    },

    {
      align: "center",
      title: "Last Completed",
      render: (record) => {
        return (
          <>
          <span>{record.last_completed ? moment(record.last_completed, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      dataIndex: "",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Resolve">
            <CheckCircleFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "green",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsCompleteModalOpen(true)
              }}
            />
            </Tooltip>
            {
              record?.last_completed && (
                <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                setIsHistoryModalOpen(true)
              }}
            />
            </Tooltip>
              )
            }
            
            <Tooltip title="Edit">
            <EditFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "#006cb8",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsEditModalOpen(true)
              }}
            />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleSubmitImageMulti = (e, status) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;
    const randomString = Math.random().toString(36).substring(2, 10);

    const updateState = (state, setState) => {
      let newFiles = [...state.newFiles];
      let filesNameList = [...state.files];

      for (let file of acceptedFiles) {
        const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;
        newFiles.push({
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        });
        filesNameList.push(newFileName);
      }

      setState(prev => ({
        ...prev,
        newFiles,
        files: filesNameList,
      }));
    };

    switch (status) {
      case 'tacho':
        updateState(tachoInfo, setTachoInfo);
        break;
      case 'license':
        updateState(licenseInfo, setLicenseInfo);
        break;
      case 'cpc':
        updateState(cpcInfo, setCpcInfo);
        break;
      default:
        console.error("Invalid status provided");
    }
  };

  const handleDeleteFile = (status, fileName) => {
    const updateFiles = (state, setState) => {
      const updatedFiles = state.files.filter((file) => file !== fileName);
      setState(prev => ({
        ...prev,
        files: updatedFiles
      }));
    };

    switch (status) {
      case 'tacho':
        updateFiles(tachoInfo, setTachoInfo);
        break;
      case 'license':
        updateFiles(licenseInfo, setLicenseInfo);
        break;
      case 'cpc':
        updateFiles(cpcInfo, setCpcInfo);
        break;
      default:
        console.error("Invalid status provided");
    }
  };


  const handleDeleteNewFile = (status, fileName) => {
    const updateState = (state, setState) => {
      const updatedFiles = state.files.filter((file) => file !== fileName);
      const updatedNewFiles = state.newFiles.filter((file) => file.name !== fileName);

      setState(prev => ({
        ...prev,
        files: updatedFiles,
        newFiles: updatedNewFiles,
      }));
    };

    switch (status) {
      case 'tacho':
        updateState(tachoInfo, setTachoInfo);
        break;
      case 'license':
        updateState(licenseInfo, setLicenseInfo);
        break;
      case 'cpc':
        updateState(cpcInfo, setCpcInfo);
        break;
      default:
        console.error("Invalid status provided");
    }
  };
  
  return (
    <>
    <div className={classes.dataSheet}>
    <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Driver Details</span>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
              fullWidth
              label="Code"
              placeholder="Code (Not Editable)"
              id="code"
              variant="outlined"
              size="small"
              disabled={id > 0 ? true : false}
              error={codeError === "" ? false : true}
              helperText={codeError}
              autoFocus={!(id > 0) ? true : false}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              fullWidth
              label="First Name"
              id="firstName"
              variant="outlined"
              size="small"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              fullWidth
              label="Last Name"
              id="lastName"
              variant="outlined"
              size="small"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={pin}
              onChange={(event) => {
                setPin(event.target.value);
              }}
              fullWidth
              label="Pin"
              id="pin"
              variant="outlined"
              size="small"
              error={pinError === "" ? false : true}
              helperText={pinError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              fullWidth
              label="Email"
              id="email"
              variant="outlined"
              size="small"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              fullWidth
              label="Phone Number"
              id="phone"
              variant="outlined"
              size="small"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Autocomplete
              id="vehicle-type"
              options={vehicleTypes}
              getOptionLabel={(option) => option.title}
              value={vehicleType}
              onChange={onChangeVehicleType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Max Vehicle Size"
                  placeholder="Max Vehicle Size"
                  error={vehicleTypeError === "" ? false : true}
                  helperText={vehicleTypeError}
                />
              )}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Autocomplete
              id="driver-type"
              options={driverTypes}
              getOptionLabel={(option) => option.title}
              value={driverType}
              onChange={onChangeDriverType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Select Driver Type"
                  placeholder="Driver Type"
                  error={driverTypeError === "" ? false : true}
                  helperText={driverTypeError}
                />
              )}
            />
            </Col>
      
          </Row>
          
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Work Days Details</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          {availableDays.length && (
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                multiple
                id="available-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                value={selectedDays}
                onChange={onChangeDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Available Days"
                    placeholder="Available Days"
                  />
                )}
              />
            </Col>
            )}
          </Row>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          {availableDays.length && (
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                multiple
                id="off-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                
                value={selectedOffDays}
                onChange={onChangeOffDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Off Days"
                    placeholder="Off Days"
                  />
                )}
              />
            </Col>
            )}
          </Row>
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">License Compliance</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          {/* <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Tacho Card Expiry<span style={{ color: "red", fontSize: "10px" }}>
                      *
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={tachoExpiry ? dayjs(tachoExpiry) : null}
                      onChange={(_, dateStr) => {
                        setTachoExpiry(dateStr)
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>License Expiry<span style={{ color: "red", fontSize: "10px" }}>
                      *
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={licenseExpiry ? dayjs(licenseExpiry) : null}
                      onChange={(_, dateStr) => {
                        setLicenseExpiry(dateStr)
                      }}
                      placeholder="Select date"
                    />
                  </Col> */}
          </Row>
          <div className="accident-box" style={{ marginTop: '10px' }}>
          <span className="accident-box-heading">Tacho Card</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Expiry<span style={{ color: "red", fontSize: "10px" }}>
                      {vehicleType?.id != '1' ? '*' : ''}
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={tachoInfo?.date ? dayjs(tachoInfo?.date) : null}
                      onChange={(_, dateStr) => {
                        setTachoInfo(prev => ({
                          ...prev,
                          date: dateStr
                        }));
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              onChange={(e) => {
                                handleSubmitImageMulti(e, 'tacho');
                              }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
          </Row>
          {tachoInfo?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {tachoInfo?.files?.length > 0 &&
                    tachoInfo?.files
                      .filter(
                        (item) =>
                          !tachoInfo?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('tacho', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/drivers/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {tachoInfo?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {tachoInfo?.newFiles?.length > 0 &&
                    tachoInfo?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('tacho', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">License</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Expiry<span style={{ color: "red", fontSize: "10px" }}>
                      *
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={licenseInfo?.date ? dayjs(licenseInfo?.date) : null}
                      onChange={(_, dateStr) => {
                        setLicenseInfo(prev => ({
                          ...prev,
                          date: dateStr
                        }));
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              onChange={(e) => {
                                handleSubmitImageMulti(e, 'license');
                              }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
          </Row>
          {licenseInfo?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {licenseInfo?.files?.length > 0 &&
                    licenseInfo?.files
                      .filter(
                        (item) =>
                          !licenseInfo?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('license', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/drivers/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {licenseInfo?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {licenseInfo?.newFiles?.length > 0 &&
                    licenseInfo?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('license', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">CPC</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Expiry<span style={{ color: "red", fontSize: "10px" }}>
                    {vehicleType?.id != '1' ? '*' : ''}
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={cpcInfo?.date ? dayjs(cpcInfo?.date) : null}
                      onChange={(_, dateStr) => {
                        setCpcInfo(prev => ({
                          ...prev,
                          date: dateStr
                        }));
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              onChange={(e) => {
                                handleSubmitImageMulti(e, 'cpc');
                              }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
          </Row>
          {cpcInfo?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {cpcInfo?.files?.length > 0 &&
                    cpcInfo?.files
                      .filter(
                        (item) =>
                          !cpcInfo?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('cpc', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/drivers/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {cpcInfo?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {cpcInfo?.newFiles?.length > 0 &&
                    cpcInfo?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('cpc', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
        </div>
        </div>
        {
          id === 'new' &&  (
            <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Contact Renewal Reminder</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Due Date<span style={{ color: "red", fontSize: "10px" }}>
                      *
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      value={date ? dayjs(date) : null}
                      onChange={(_, dateStr) => {
                        setDate(dateStr)
                      }}
                      placeholder="Select date"
                    />
                  </Col>
          </Row>
          <Alert style={{ marginTop: '5px'}}
            message="Every Six months" 
            type="info" 
            showIcon 
            />
        </div>
          ) 
        }
    </div>
    <div style={{ width: "100%" }}>
      {/* <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h3> {id > 0 ? "Update" : "Add"} Driver</h3>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={10} sm={4}>
            <TextField
              value={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
              label="Code"
              placeholder="Code (Not Editable)"
              id="code"
              variant="outlined"
              size="small"
              disabled={id > 0 ? true : false}
              error={codeError === "" ? false : true}
              helperText={codeError}
              autoFocus={!(id > 0) ? true : false}
            />
            <br />
            <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              label="First Name"
              id="firstName"
              variant="outlined"
              size="small"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />
            <br />
            <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              label="Last Name"
              id="lastName"
              variant="outlined"
              size="small"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
            <TextField
              value={pin}
              onChange={(event) => {
                setPin(event.target.value);
              }}
              label="Pin"
              id="pin"
              variant="outlined"
              size="small"
              error={pinError === "" ? false : true}
              helperText={pinError}
            />
            <br />
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label="Email"
              id="email"
              variant="outlined"
              size="small"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            <br />
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              label="Phone Number"
              id="phone"
              variant="outlined"
              size="small"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
            <br />
            <Autocomplete
              id="vehicle-type"
              options={vehicleTypes}
              getOptionLabel={(option) => option.title}
              value={vehicleType}
              onChange={onChangeVehicleType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Max Vehicle Size"
                  placeholder="Max Vehicle Size"
                  error={vehicleTypeError === "" ? false : true}
                  helperText={vehicleTypeError}
                />
              )}
            />
            <br />
            <Autocomplete
              id="driver-type"
              options={driverTypes}
              getOptionLabel={(option) => option.title}
              value={driverType}
              onChange={onChangeDriverType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Select Driver Type"
                  placeholder="Driver Type"
                  error={driverTypeError === "" ? false : true}
                  helperText={driverTypeError}
                />
              )}
            />
            <br />
            {availableDays.length && (
              <Autocomplete
                multiple
                id="available-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                value={selectedDays}
                onChange={onChangeDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Available Days"
                    placeholder="Available Days"
                  />
                )}
              />
            )}
            <br />
            {availableDays.length && (
              <Autocomplete
                multiple
                id="off-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                
                value={selectedOffDays}
                onChange={onChangeOffDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Off Days"
                    placeholder="Off Days"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
        <Message key={messageKey} type={type} msg={loadMessage} />
      </Box> */}
      <div>
        {id > 0 && 
        <>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Contact Renewal Reminder</span>
          <Table dataSource={remainingContactReminders} columns={remainingReminderColumns} style={{ marginTop: "4px"}} pagination={false} />
        </div>
        </>
        }
        <div style={{ display: "flex", justifyContent: 'end' ,marginTop: "40px" }}>
          <FormButton type="close" onClick={onClickCancel}>
            Cancel
          </FormButton>
          <FormButton type="save" onClick={handleSubmit}>
            Save
          </FormButton>
        </div>
        <Message key={messageKey} type={type} msg={loadMessage} />
        <Message type={type} msg={loadMessage} />

      <ContactHistoryModal isModalOpen={isHistoryModalOpen} setIsModalOpen={setIsHistoryModalOpen} history={completedContactReminders} setHistory={setCompletedContactReminders} />
      <EditContactModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} getData={getData} />
      <ResolveContactModal isModalOpen={isCompleteModalOpen} setIsModalOpen={setIsCompleteModalOpen} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} getData={getData} />
      
      </div>
    </div>
    </>
  );
}
